import React from 'react';
import { Tag } from '@in/component-library';
import { OneWayInStatus } from 'src/api/v2';

import { translateStatusTag } from '../helpers/statusTags';

type Props = {
  status: OneWayInStatus | undefined;
};
const OneWayInOpportunityStatusTag: React.FC<Props> = ({ status }) => {
  const determineTagType = () => {
    let tagType = 'success';

    if (!status || status === OneWayInStatus.Received || status === OneWayInStatus.New) {
      tagType = 'info';
    }

    if (status === OneWayInStatus.Closed || status === OneWayInStatus.Forwarded) {
      tagType = 'generic';
    }

    return tagType;
  };

  return <Tag type={determineTagType()}>{translateStatusTag(status)}</Tag>;
};

export default OneWayInOpportunityStatusTag;
