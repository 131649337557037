import React from 'react';
import Table from 'src/components/Table';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FaIcon } from '@in/component-library';
import { useTranslation } from 'react-i18next';
import TertiaryButton from 'src/components/TertiaryButton/TertiaryButton';
import { formatDateToNOString } from 'src/utils/FormatValue';

import './HistoryTableSection.scss';

type Props = {
  deliverDate: string;
  receivedDate: string;
  openEmailView: () => void;
  hasEmail: boolean;
};

const HistoryTableINSection: React.FC<Props> = ({ deliverDate, receivedDate, openEmailView, hasEmail }) => {
  const { t: tCommon } = useTranslation();
  const { t: tOneWayIn } = useTranslation('oneWayIn');

  const receivedDateFormated = React.useMemo(() => {
    const splits = receivedDate.split('.');
    if (splits.length < 3) {
      return receivedDate;
    }

    return formatDateToNOString(new Date(`${splits[1]}.${splits[0]}.${splits[2]}`));
  }, [receivedDate]);

  return (
    <>
      <Table.Row className="owi-history__table-header--no-bottom-border">
        <Table.Cell colSpan={5}>
          <strong>{deliverDate ? deliverDate : receivedDateFormated}</strong>
        </Table.Cell>
      </Table.Row>

      <Table.Row className="one-way-in-history__row-content">
        <Table.Cell data-level="1">
          <FaIcon icon={icon({ prefix: 'fal', iconName: 'paper-plane' }) as IconDefinition} />
        </Table.Cell>
        <Table.Cell data-level="1">
          <div className="display--flex flex-direction--column">
            <strong>{tOneWayIn('status.Referred')}</strong>
            <small>
              {tCommon('to')} {tOneWayIn('municipalityFirstLine')}
            </small>
          </div>
        </Table.Cell>
        <Table.Cell data-level="1">{tCommon('innovationNorway')}</Table.Cell>
        <Table.Cell data-level="1"></Table.Cell>
        <Table.Cell data-level="1"></Table.Cell>
      </Table.Row>

      <Table.Row className="one-way-in-history__row-content">
        <Table.Cell data-level="1">
          <FaIcon icon={icon({ prefix: 'fal', iconName: 'envelope' }) as IconDefinition} />
        </Table.Cell>
        <Table.Cell data-level="1">
          <div className="display--flex flex-direction--column">
            <strong>{tCommon('email')}</strong>
            <small>{tOneWayIn('history.sentToCustomer').toLowerCase()}</small>
          </div>
        </Table.Cell>
        <Table.Cell data-level="1">{tCommon('innovationNorway')}</Table.Cell>
        <Table.Cell data-level="1"></Table.Cell>
        <Table.Cell data-level="1">
          {hasEmail && (
            <TertiaryButton standardizedSize={'x-large'} onClick={() => !!openEmailView && openEmailView()}>
              <FaIcon icon={icon({ prefix: 'fal', iconName: 'browsers' }) as IconDefinition} />
              {tOneWayIn('history.readEmail')}
            </TertiaryButton>
          )}
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell colSpan={5}>
          <strong>{receivedDateFormated}</strong>
        </Table.Cell>
      </Table.Row>

      <Table.Row className="one-way-in-history__row-content">
        <Table.Cell data-level="1">
          <FaIcon icon={icon({ prefix: 'fal', iconName: 'envelope-open' }) as IconDefinition} />
        </Table.Cell>
        <Table.Cell data-level="1">
          <div className="display--flex flex-direction--column">
            <strong>{tOneWayIn('status.Opened')}</strong>
          </div>
        </Table.Cell>
        <Table.Cell data-level="1">{tCommon('innovationNorway')}</Table.Cell>
        <Table.Cell data-level="1"></Table.Cell>
        <Table.Cell data-level="1"></Table.Cell>
      </Table.Row>
    </>
  );
};

// This needs to be added for Table.Body to render the rows.
HistoryTableINSection.displayName = 'Tr';

export default HistoryTableINSection;
