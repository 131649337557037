import { ErrorMessage } from '@in/component-library';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ClusterMemberDto, CustomerInfoDto } from 'src/api/v2';
import Skeleton from 'src/components/Skeleton/Skeleton';
import Table from 'src/components/Table';
import { useMunicipalityDetails } from 'src/hooks/use-municipality-details';
import CompanyEnterpriseStatusTag from './CompanyEnterpriseStatusTag';
import { formatDateToNOString } from 'src/utils/FormatValue';

type Props = {
  company: CustomerInfoDto;
  member?: ClusterMemberDto;
  id: string;
  isActive: boolean;
};

const CompanyInformationAboutTab: React.FC<Props> = ({ company: company, member, id, isActive }) => {
  const { t: tCommon } = useTranslation();
  const { t: tSiva } = useTranslation('siva');

  const municipalityCode =
    company.visitorAddress?.municipalityCode || company.postalAddress?.municipalityCode || '';

  const municipalityDetailsQuery = useMunicipalityDetails(municipalityCode);

  const tableData = useMemo(() => {
    const data: { key: string; label: React.ReactNode; value: React.ReactNode }[] = [
      {
        key: 'municipality',
        label: tCommon('municipality'),
        value: company.visitorAddress?.municipality || company.postalAddress?.municipality || '',
      },
    ];

    if (municipalityDetailsQuery.isLoading) {
      data.push({
        key: 'county',
        label: tCommon('county'),
        value: <Skeleton width="8rem" />,
      });
    } else {
      data.push({
        key: 'county',
        label: tCommon('county'),
        value: municipalityDetailsQuery.data?.fylkesnavn || '',
      });
    }

    if (company.status?.dateOfIncorporation) {
      data.push({
        key: 'establishmentDate',
        label: tCommon('establishmentDate'),
        value: formatDateToNOString(
          new Date(company.status?.dateOfIncorporation),
          false,
          '{date}.{month}.{year}',
          true,
        ),
      });
    }

    if (member?.memberSince) {
      {
        data.push({
          key: 'registeredDate',
          label: tSiva('companyTabs.about.registeredDate'),
          value: formatDateToNOString(new Date(member?.memberSince), false, '{date}.{month}.{year}', true),
        });
      }
    }

    data.push(
      ...[
        {
          key: 'organizationType',
          label: tCommon('companyType'),
          value: company.organizationType?.typeDescription || '',
        },
        {
          key: 'numberOfEmployees',
          label: tCommon('numberOfEmployees'),
          value: company.employeeInformation?.numberOfEmployees || 0,
        },
      ],
    );

    if (member && company) {
      data.push({
        key: 'status',
        label: tSiva('companyTabs.economy.enterpriseStatus'),
        value: <CompanyEnterpriseStatusTag orgNo={company.publicId} />,
      });
    }

    if (company.nace1) {
      if (company.nace1.code?.startsWith('94')) {
        data.push({
          key: 'nace',
          label: tCommon('naceCode'),
          value: (
            <div className="display--flex flex-direction--column gap--1">
              <span>{`${company.nace1.code} ${company.nace1.descriptionNorwegian}`}</span>
              <ErrorMessage id="invalid-nace" errorMessage={tSiva('companyTabs.about.nace.warning')} />
            </div>
          ),
        });
      } else {
        data.push({
          key: 'nace',
          label: tCommon('naceCode'),
          value: `${company.nace1.code} ${company.nace1.descriptionNorwegian}`,
        });
      }
    }

    data.push({
      key: 'contactPerson',
      label: tCommon('ceo'),
      value: company.contactPerson ? `${company.contactPerson?.fullName}` : tSiva('no.ceo'),
    });

    return data;
  }, [
    tCommon,
    company,
    municipalityDetailsQuery.isLoading,
    municipalityDetailsQuery.data?.fylkesnavn,
    member,
    tSiva,
  ]);

  return (
    <div id={id} role="tabpanel" tabIndex={0} hidden={!isActive}>
      <h3>{tSiva('companyTabs.about.title')}</h3>

      <Table>
        <Table.Body>
          {tableData.map((item) => (
            <Table.Row key={item.key}>
              <Table.Cell className="width--256px max-width--256px">
                <strong>{item.label}</strong>
              </Table.Cell>
              <Table.Cell>{item.value}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default CompanyInformationAboutTab;
