import { Button } from '@in/component-library';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OneWayInServiceDto } from 'src/api/v2';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import useToggle from '../../../hooks/use-toggle';
import { Counties } from '../constants/counties';
import useOneWayIn from '../hooks/use-one-way-in';
import useOneWayInServices from '../hooks/use-one-way-in-services';
import OneWayInRecommendService from './recommend-service/OneWayInRecommendService';
import RecommendServiceListItem from './recommend-service/RecommendServiceListItem';
import OneWayInRegisterService from './register-service/OneWayInRegisterService';

const OneWayInServices: React.FC<{ formId: string | undefined }> = ({ formId }) => {
  const { oneWayInOpportunityFormQuery } = useOneWayIn(formId);
  const { oneWayInOpportunityServicesQuery } = useOneWayInServices();
  const { t: tOneWayIn } = useTranslation('oneWayIn');

  const {
    isActive: isRecomendServiceActive,
    activate: activateRecomendService,
    deactivate: deactivateRecomendService,
  } = useToggle(false);

  const {
    isActive: isRegisterServiceActive,
    activate: activateRegisterService,
    deactivate: deactivateRegisterService,
  } = useToggle(false);

  const isClosed = useMemo(() => {
    if (!oneWayInOpportunityFormQuery.data?.opportunity) return true;

    return oneWayInOpportunityFormQuery.data?.opportunity.isClosed;
  }, [oneWayInOpportunityFormQuery.data]);

  const { registeredServiceIds, recommendedServiceIds } = useMemo(() => {
    if (!oneWayInOpportunityFormQuery.data || !oneWayInOpportunityFormQuery.data.opportunity) {
      return {
        registeredServiceIds: [] as OneWayInServiceDto[],
        recommendedServiceIds: [] as OneWayInServiceDto[],
      };
    }

    return {
      registeredServiceIds: oneWayInOpportunityFormQuery.data.opportunity.history.reduce(
        (a, b) => a.concat(b.registeredServices),
        [] as OneWayInServiceDto[],
      ),
      recommendedServiceIds: oneWayInOpportunityFormQuery.data.opportunity.history.reduce(
        (a, b) => a.concat(b.recommendedServices),
        [] as OneWayInServiceDto[],
      ),
    };
  }, [oneWayInOpportunityFormQuery.data]);

  const { registeredServices, recommendedServices } = useMemo(() => {
    const recommendedServices = recommendedServiceIds
      .map((id) => oneWayInOpportunityServicesQuery.data?.find((service) => service.id === id.id))
      .filter((service) => service)
      .map((service) => ({
        ...service,
        counties: service!.municipalities
          .map((municipality) =>
            Counties.find((county) =>
              county.municipalities.some((countyMunicipality) => countyMunicipality === municipality),
            ),
          )
          .filter((county) => county)
          .map((county) => county!.countyName)
          .filter((value, index, array) => array.indexOf(value) === index),
      }));

    const registeredServices = registeredServiceIds
      .map((id) => oneWayInOpportunityServicesQuery.data?.find((service) => service.id === id.id))
      .filter((service) => service)
      .map((service) => ({
        ...service,
        counties: service!.municipalities
          .map((municipality) =>
            Counties.find((county) =>
              county.municipalities.some((countyMunicipality) => countyMunicipality === municipality),
            ),
          )
          .filter((county) => county)
          .map((county) => county!.countyName)
          .filter((value, index, array) => array.indexOf(value) === index),
      }));

    return {
      registeredServices: registeredServices,
      recommendedServices: recommendedServices,
    };
  }, [registeredServiceIds, recommendedServiceIds, oneWayInOpportunityServicesQuery]);

  return (
    <div>
      <div>
        <Button
          className="margin-right--2"
          variant="outlined"
          onClick={activateRegisterService}
          disabled={isClosed}
        >
          {tOneWayIn('button.addService')}
        </Button>
        <Button variant="outlined" onClick={activateRecomendService} disabled={isClosed}>
          {tOneWayIn('button.RecommendService')}
        </Button>
      </div>
      {registeredServiceIds.length + recommendedServiceIds.length === 0 && (
        <div className="margin-top--2 margin-bottom--2">
          {tOneWayIn('services.NoRecommendedOrRegisteredServices')}
        </div>
      )}
      {registeredServices.length > 0 && (
        <div className="margin-top--4 margin-bottom--2">
          <div>
            <b>{tOneWayIn('services.register.deliveredServices')}</b>
          </div>

          <div className="width--80">
            {registeredServices.map((service, index) => {
              if (!service) return;

              return (
                <RecommendServiceListItem
                  key={`${service.id}-${index}`}
                  id={service.id || ''}
                  name={service.name || ''}
                  contactPerson={service.contactName || ''}
                  contactPersonEmail={service.contactEmail || ''}
                  counties={service.counties}
                  prosecutor={service.businessName || ''}
                  tags={['Prefase', 'Tidlig']}
                  url={service.internalUrl || ''}
                  checked={false}
                />
              );
            })}
          </div>
        </div>
      )}
      {recommendedServices.length > 0 && (
        <div className="margin-top--4 margin-bottom--2">
          <div>
            <b>{tOneWayIn('opportunityDetails.RecommendedServices')}</b>
          </div>

          <div className="width--80">
            {recommendedServices.map((service, index) => {
              if (!service) return;

              return (
                <RecommendServiceListItem
                  key={`${service.id}-${index}`}
                  id={service.id || ''}
                  name={service.name || ''}
                  contactPerson={service.contactName || ''}
                  contactPersonEmail={service.contactEmail || ''}
                  counties={service.counties}
                  prosecutor={service.businessName || ''}
                  tags={['Prefase', 'Tidlig']}
                  url={service.internalUrl || ''}
                  checked={false}
                />
              );
            })}
          </div>
        </div>
      )}
      <div>
        <DialogContainer
          title={tOneWayIn('button.RecommendService')}
          className="recommend-service-modal"
          show={isRecomendServiceActive}
          onClose={deactivateRecomendService}
        >
          {isRecomendServiceActive && (
            <OneWayInRecommendService
              opportunityId={formId}
              updating={false}
              deativateModal={deactivateRecomendService}
            />
          )}
        </DialogContainer>
        <DialogContainer
          title={tOneWayIn('button.addService')}
          className="register-service-modal"
          show={isRegisterServiceActive}
          onClose={deactivateRegisterService}
        >
          {isRegisterServiceActive && (
            <OneWayInRegisterService
              opportunityId={formId}
              updating={false}
              deativateModal={deactivateRegisterService}
            />
          )}
        </DialogContainer>
      </div>
    </div>
  );
};
export default OneWayInServices;
