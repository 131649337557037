import {
  Button,
  ButtonVariant,
  CallbackResponse,
  FileItem,
  FileList,
  FileUpload,
  Notification,
} from '@in/component-library';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ClusterMemberCreateDto, ClusterMemberDto } from 'src/api/v2';
import { ClusterMemberTypeArray } from 'src/constants/cluster-member-type';
import {
  AllowedFileTypes,
  ExcelImportClusterMemberAssociateLeadSettingsChangelog,
  ExcelImportClusterMemberSettingsChangelog,
} from 'src/constants/excel-import';
import { defaultClusterMemberDetails } from 'src/features/members';
import useExcel from 'src/hooks/use-excel';
import useMembers from 'src/hooks/use-members';
import { resources } from 'src/i18n';
import { ColumnSettings } from 'src/utils/excel.service';
import { organizationNumberRegex } from 'src/utils/regex';
import { toastPromise } from 'src/utils/toast';
import ImportMembersDuplicateMessage from './ImportMembersDuplicateMessage';
import ImportMembersInvalidMessage, { InvalidMember } from './ImportMembersInvalidMessage';
import ImportMembersSuccessMessage from './ImportMembersSuccessMessage';
import ExcelChangelog from 'src/components/ExcelChangelog/ExcelChangelog';
import { ClusterMemberCategories } from 'src/constants/cluster-member-category';
import hotjarEvent from 'src/utils/hotjarEvent';

type ResourceBaseKeys = keyof typeof resources['nb']['resourceBase'];
type ErrorKeys = keyof typeof resources['nb']['error'];

interface Props {
  onImport: () => void;
  onCancel: () => void;

  clusterMemberCategoryId: string;

  translations: {
    fileName: ResourceBaseKeys;
    createError: ErrorKeys;
    noneToImport: ResourceBaseKeys;
    submitButton: ResourceBaseKeys;
  };

  excelTemplate: ColumnSettings<ClusterMemberCreateDto>;
}

const ImportClusterMemberForm: React.FC<Props> = ({
  onImport,
  onCancel,
  clusterMemberCategoryId,
  excelTemplate,
  translations,
}) => {
  const { t: tCommon } = useTranslation();
  const { t: tError } = useTranslation('error');
  const { t: tResourceBase } = useTranslation('resourceBase');

  const { combined, createMutation } = useMembers();

  const [files, setFiles] = useState<FileItem[]>([]);
  const [createRecords, setCreateRecords] = useState<ClusterMemberCreateDto[]>([]);
  const [existingMembers, setExistingMembers] = useState<ClusterMemberDto[]>([]);
  const [error, setError] = useState<string>('');

  const [invalidMembers, setInvalidMembers] = useState<InvalidMember[]>([]);

  const excelChangelog = useMemo(
    () =>
      clusterMemberCategoryId === ClusterMemberCategories.Member.id ||
      clusterMemberCategoryId === ClusterMemberCategories.CoreMember.id
        ? ExcelImportClusterMemberSettingsChangelog
        : ExcelImportClusterMemberAssociateLeadSettingsChangelog,
    [clusterMemberCategoryId],
  );
  const { size, type, downloadFile, importFile } = useExcel<ClusterMemberCreateDto>(excelTemplate);

  const templateFile = useMemo(
    () => ({
      id: 'clusterMember',
      fileName: tResourceBase(translations.fileName),
      fileType: type,
      fileSize: size,
      onClick: () => {
        downloadFile(`import-mal_medlem`);
      },
    }),
    [downloadFile, size, tResourceBase, translations.fileName, type],
  );

  const handleOnImport = () => {
    const promises = createRecords.map((record) => createMutation.mutateAsync(record));

    toastPromise(Promise.all(promises), {
      error: tError(translations.createError),
    }).then(() => {
      onImport();
      hotjarEvent('members imported');
    });
  };

  const handleImportClusterMembers = (items: Array<ClusterMemberCreateDto>) => {
    setError('');

    items.forEach((item, index) => {
      const orgNo = item.organizationNumber?.replace(/\s/g, '');

      const found = orgNo
        ? combined.find((x) => x.organizationNumber === orgNo)
        : combined.find((x) => x.name?.toLowerCase() === item.name?.toLowerCase());
      if (found) {
        setExistingMembers((prev) => [...prev, found]);
        return;
      }

      if (orgNo && !orgNo?.match(organizationNumberRegex)) {
        setInvalidMembers((prevValue) => [
          ...prevValue,
          {
            name: item.name || '',
            organizationNumber: item.organizationNumber || '',
            rowNumber: index + 2,
            reason: tResourceBase('organizationNumber.invalidFormat'),
          },
        ]);
        return;
      }

      if (!item.name) {
        setInvalidMembers((prevValue) => [
          ...prevValue,
          {
            name: item.name || '',
            organizationNumber: item.organizationNumber || '',
            rowNumber: index + 2,
            reason: tResourceBase('missing.name'),
          },
        ]);
        return;
      }

      if (!item.clusterMemberTypeId) {
        setInvalidMembers((prevValue) => [
          ...prevValue,
          {
            name: item.name || '',
            organizationNumber: item.organizationNumber || '',
            rowNumber: index + 2,
            reason: tResourceBase('missing.type'),
          },
        ]);
        return;
      }

      if (isNaN(item.memberSince!.getTime())) {
        setInvalidMembers((prevValue) => [
          ...prevValue,
          {
            name: item.name || '',
            organizationNumber: item.organizationNumber || '',
            rowNumber: index + 2,
            reason: tResourceBase('invalid.date'),
          },
        ]);
        return;
      }

      const clusterMemberType = ClusterMemberTypeArray.find((x) => {
        return x.name?.toLowerCase() === item.clusterMemberTypeId?.toLowerCase();
      });

      const record: ClusterMemberCreateDto = {
        clusterId: '', // blir satt i createMutation
        name: item.name,
        memberSince: item.memberSince,
        clusterMemberTypeId: clusterMemberType?.id,
        clusterMemberCategoryId: clusterMemberCategoryId,
        organizationNumber: item.organizationNumber,
        international: `${item.international}`.toLowerCase() === 'ja',
        clusterMemberFeeId: undefined,
        establishedDate: undefined,
        interestAreaIds: [],
        mailingAddress: undefined,
        billingAddress: undefined,
        description: undefined,
        orderNumber: undefined,
        details: {
          ...defaultClusterMemberDetails,
        },
      };

      setCreateRecords((value) => [...value, record]);
    });
  };

  const handleFileUpload = (fileItem: FileItem) => {
    const { file } = fileItem;

    if (file) {
      importFile(file)
        .then((data) => {
          setCreateRecords([]);
          handleImportClusterMembers(data);
        })
        .catch(() => {
          toast.error(tError('excel.import.error'));
        });
    }
  };

  const handleUploadCallback = async (fileItem: FileItem): Promise<CallbackResponse> => {
    if (!AllowedFileTypes.some((allowedFileType: string) => allowedFileType === fileItem.fileType)) {
      toast.error(tError('excel.import.invalidFileType'));

      setFiles([]);

      return { status: true, message: tError('excel.import.invalidFileType') };
    }

    setFiles([fileItem]);
    handleFileUpload(fileItem);

    return { status: true, message: '' };
  };

  const handleDeletionCallback = async (): Promise<CallbackResponse> => {
    setFiles([]);
    setCreateRecords([]);
    setExistingMembers([]);

    return { status: true, message: '' };
  };

  const handleOnSubmit = () => {
    if (createRecords.length > 0) {
      handleOnImport();
    } else {
      setError(tResourceBase(translations.noneToImport));
    }
  };

  return (
    <div>
      <p>{tResourceBase('excel.downloadTemplateTips')}:</p>

      <FileList files={[templateFile]} downloadText={tCommon('downloadTemplate')} />

      <ExcelChangelog changelogs={excelChangelog} />

      <FileUpload
        name="file_upload"
        files={files}
        fileTypes="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        uploadCallback={handleUploadCallback}
        deletionCallback={handleDeletionCallback}
      />

      <ImportMembersSuccessMessage items={createRecords} />
      <ImportMembersDuplicateMessage items={existingMembers} />
      <ImportMembersInvalidMessage items={invalidMembers} />

      <Notification type="error" fullWidth className="margin-bottom--2" visible={error !== ''}>
        {error}
      </Notification>

      <div className="display--flex gap--1">
        <Button type="button" onClick={handleOnSubmit}>
          {tResourceBase(translations.submitButton)}
        </Button>

        <Button type="button" variant={ButtonVariant.Outlined} onClick={onCancel}>
          {tCommon('cancel')}
        </Button>
      </div>
    </div>
  );
};

export default ImportClusterMemberForm;
