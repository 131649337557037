import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import { Operation, PermissionDtoV2, UsersService } from 'src/api/v2';

import { AUTH_KEY } from 'src/constants/hook-keys';

import useCluster from 'src/features/cluster/hooks/use-cluster';

export type ModuleTypes =
  | 'AccessControl'
  | 'AccountSettings'
  | 'AnnualApplication'
  | 'Application'
  | 'Assignments'
  | 'ClusterMember'
  | 'Collaborators'
  | 'Core'
  | 'Course'
  | 'DevelopmentProject'
  | 'Events'
  | 'FF_Experiment'
  | 'FF_Goals'
  | 'Financing'
  | 'FocusArea'
  | 'Inkind'
  | 'Leads'
  | 'MainGoal'
  | 'MemberEconomy'
  | 'OneWayIn'
  | 'Operation'
  | 'PortalProject'
  | 'Report'
  | 'ResourceBase'
  | 'SivaAdmin'
  | 'SivaCore'
  | 'Statistics'
  | 'UI_SivaBusinessGarden'
  | 'UI_SivaIncubator'
  | 'WorkGroup';

type AccessType = {
  read: boolean;
  write: boolean;
};

type UseAuthProps = {
  enabled?: boolean;
};

function useAuth({ enabled }: UseAuthProps = { enabled: true }) {
  const { cluster } = useCluster();

  const [service] = useState(new UsersService());

  const query = useQuery<PermissionDtoV2>(
    [AUTH_KEY, { clusterId: cluster?.id }],
    async () => {
      return await service.getUserRolePermissionsV2(cluster!.id);
    },
    {
      enabled: !!cluster && enabled,
    },
  );

  const checkAccess = useCallback(
    (module: ModuleTypes): AccessType => {
      if (query.data) {
        const item = query.data.permissions[module];
        if (item !== undefined) {
          return {
            read: item !== Operation.None,
            write: item === Operation.Write,
          };
        }
      }

      return {
        read: false,
        write: false,
      };
    },
    [query.data],
  );

  return {
    ...query,

    checkAccess,
  };
}

export default useAuth;
