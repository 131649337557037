import { Button, ButtonVariant, Theme } from '@in/component-library';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TimeEntryDto, TimeEntryStatus } from 'src/api/v2';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import useTimeEntries from 'src/hooks/use-time-entries';
import { toastPromise } from 'src/utils/toast';

type Props = {
  show: boolean;
  selectedTimeEntries: TimeEntryDto[];
  onClose: () => void;
};

const DeleteInkindModal: React.FC<Props> = ({ show, selectedTimeEntries, onClose }) => {
  const { t: tCommon } = useTranslation();
  const { t: tSelfEffort } = useTranslation('selfEffort');

  const { deleteTimeEntryMutation } = useTimeEntries();

  const approvedNumberMemo: number = useMemo(() => {
    return selectedTimeEntries.filter((x) => x.status === TimeEntryStatus.Counting).length;
  }, [selectedTimeEntries]);

  const deleteEntries = () => {
    const promise = deleteTimeEntryMutation.mutateAsync(selectedTimeEntries.map((timeEntry) => timeEntry.id));

    toastPromise(promise, {
      pending: `${tCommon('deleting')}...}`,
      success: `${selectedTimeEntries.length} ${tCommon('deleted')}.`,
      error: tSelfEffort('couldNotDelete'),
    }).then(() => {
      onClose();
    });
  };

  return (
    <DialogContainer title={tSelfEffort('deleteSelfEffort')} show={show} onClose={onClose}>
      <div>
        {approvedNumberMemo > 0 && (
          <p>
            {approvedNumberMemo === 1
              ? tSelfEffort('numberOfApprovedRows_one')
              : tSelfEffort('numberOfApprovedRows_many', { approvedNumber: approvedNumberMemo })}
          </p>
        )}
        <p>{tSelfEffort('deleteAreYouSure')}</p>
      </div>
      <div className="display--flex gap--1 justify-content--space-between padding-top--3">
        <Button
          type="button"
          theme={Theme.Neutral}
          onClick={onClose}
          disabled={deleteTimeEntryMutation.isLoading}
          variant={ButtonVariant.Outlined}
        >
          {tCommon('cancel')}
        </Button>

        <Button
          type="button"
          theme={Theme.Danger}
          onClick={deleteEntries}
          disabled={deleteTimeEntryMutation.isLoading}
        >
          {tCommon('delete')}
        </Button>
      </div>
    </DialogContainer>
  );
};

export default DeleteInkindModal;
