import React from 'react';
import { useCluster } from 'src/features/cluster';
import { StatusProgramId } from 'src/constants/status-program';
import { ProgramRendererType } from 'src/types/ProgramRendererType';
import { StatusProgramService } from 'src/api/v2';
import { useQuery } from 'react-query';
import { STATUS_PROGRAMS_KEY } from 'src/constants/hook-keys';

const useStatusPrograms = () => {
  const [statusProgramService] = React.useState(new StatusProgramService());
  const { cluster } = useCluster();

  const statusProgramsQuery = useQuery(
    [STATUS_PROGRAMS_KEY],
    () => statusProgramService.getStatusProgramList(),
    {
      retry: false,
      staleTime: 1000 * 60 * 30, // 30 min refetching on mount
      refetchOnMount: true,
    },
  );

  const { clusterStatusesIds, owiStatusesIds, sivaStatusesIds } = React.useMemo(() => {
    if (!statusProgramsQuery.data) {
      return {
        clusterStatusesIds: [] as string[],
        owiStatusesIds: [] as string[],
        sivaStatusesIds: [] as string[],
      };
    }

    const clusterStatuses =
      statusProgramsQuery.data?.find((statusProgram) => statusProgram.id === StatusProgramId.Cluster)
        ?.clusterStatuses || [];
    const owiStatuses =
      statusProgramsQuery.data?.find((statusProgram) => statusProgram.id === StatusProgramId.OneWayIn)
        ?.clusterStatuses || [];
    const sivaStatusesIds =
      statusProgramsQuery.data?.find((statusProgram) => statusProgram.id === StatusProgramId.Siva)
        ?.clusterStatuses || [];

    return {
      clusterStatusesIds: clusterStatuses.map((status) => status.id),
      owiStatusesIds: owiStatuses.map((status) => status.id),
      sivaStatusesIds: sivaStatusesIds.map((status) => status.id),
    };
  }, [statusProgramsQuery.data]);

  const clusterStatusRulesMap: Record<ProgramRendererType, string[]> = React.useMemo(() => {
    return {
      cluster: [...clusterStatusesIds],
      'one-way-in': [...owiStatusesIds],
      siva: [...sivaStatusesIds],

      '*': [], // Trenger ingen i seg, denne gjelder for alle, eller som catcher
    };
  }, [clusterStatusesIds, owiStatusesIds, sivaStatusesIds]);

  const isProgramSiva = React.useMemo(() => {
    if (!cluster) return false;

    if (cluster.clusterStatusId && clusterStatusRulesMap.siva.includes(cluster.clusterStatusId)) return true;

    return false;
  }, [cluster, clusterStatusRulesMap]);

  const isProgramOneWayIn = React.useMemo(() => {
    if (!cluster) return false;

    if (cluster.clusterStatusId && clusterStatusRulesMap['one-way-in'].includes(cluster.clusterStatusId))
      return true;

    return false;
  }, [cluster, clusterStatusRulesMap]);

  const isProgramCluster = React.useMemo(() => {
    if (!cluster) return false;

    if (cluster.clusterStatusId && clusterStatusRulesMap.cluster.includes(cluster.clusterStatusId))
      return true;

    return false;
  }, [cluster, clusterStatusRulesMap.cluster]);

  return {
    statusProgramsQuery,
    clusterStatusRulesMap,
    isProgramSiva,
    isProgramOneWayIn,
    isProgramCluster,
  };
};

export default useStatusPrograms;
