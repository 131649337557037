import React, { useMemo } from 'react';
import { Button, FaIcon } from '@in/component-library';
import useCompanyContract from '../hooks/use-company-contract';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import { formatDateToNOString } from 'src/utils/FormatValue';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { NavLink, useParams } from 'react-router-dom';
import { PageParams } from '../types/PageParams';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'src/utils/string';
import EditContract from '../components/EditContract';
import ErrorSupportMessage from 'src/features/siva/components/ErrorSupportMessage';
import { convertIdToNumber } from '../utils/idConversion';

import '../styles/Contract.scss';
import EditPhase from '../components/EditPhase';
import { SivaPhase } from 'src/api/v2';
import ContractHistoric from '../components/ContractHistoric';
import EndContract from '../components/EndContract';
import { useAuth } from 'src/features/authorization';
import { ROUTES } from 'src/navigation';

const CompanyContract: React.FC = () => {
  const { contractId } = useParams<PageParams>();
  const {
    contract,
    company,
    isLoading,
    isError,
    contractAdmissionDate,
    contractEndDate,
    currentPhase,
    admissionFormDate,
    enrouteFormDate,
    exitFormDate,
    companyContractsCount,
  } = useCompanyContract(convertIdToNumber(contractId));
  const { t: tCommon } = useTranslation();
  const { t: tSiva } = useTranslation('siva');
  const { checkAccess } = useAuth();

  const sivaCoreWriteAccess = checkAccess('SivaCore').write;
  const isBusinessGarden = checkAccess('UI_SivaBusinessGarden').read;

  const [subpage, setSubpage] = React.useState<'edit' | 'phase' | 'endContract' | 'none'>('none');

  const linkToCompanyContracts = useMemo(
    () =>
      companyContractsCount > 1 && company ? (
        <div className="margin-top--5">
          <NavLink
            to={ROUTES.SIVA.IDEAS_AND_COMPANIES.COMPANY_DETAILS.CONTRACTS(contractId!)}
            className="siva-contract-overview__back-link"
          >
            <FaIcon className="margin-right--1" icon={icon({ prefix: 'fal', iconName: 'arrow-left' })} />{' '}
            {tSiva('company.details.contract.backToIdeasOrContracts')}
          </NavLink>
        </div>
      ) : (
        <div className="margin-top--5">
          <NavLink to={ROUTES.SIVA.IDEAS_AND_COMPANIES.BASE} className="siva-contract-overview__back-link">
            <FaIcon className="margin-right--1" icon={icon({ prefix: 'fal', iconName: 'arrow-left' })} />{' '}
            {tSiva('company.details.contract.backToIdeasOrContracts')}
          </NavLink>
        </div>
      ),
    [company, companyContractsCount, contractId, tSiva],
  );

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!contract || isError) {
    return (
      <div>
        {linkToCompanyContracts}
        <div className="margin-top--5 margin-left--3">
          <h2>{tSiva('company.details.contract.overview.error.header')}</h2>
          <ErrorSupportMessage />
        </div>
      </div>
    );
  }

  switch (subpage) {
    case 'edit':
      return <EditContract onCancel={() => setSubpage('none')} />;

    case 'phase':
      return <EditPhase onCancel={() => setSubpage('none')} />;
    case 'endContract':
      return <EndContract onCancel={() => setSubpage('none')} />;

    default:
      return (
        <div>
          {linkToCompanyContracts}
          <div className="display--flex margin-bottom--5">
            <Button
              className="siva-contract-overview__buttons"
              iconName="fa-edit"
              iconPosition="left"
              size="medium"
              theme="neutral"
              variant="link"
              onClick={() => setSubpage('edit')}
            >
              {tSiva('company.details.contract.editContract')}
            </Button>
            {sivaCoreWriteAccess &&
              !isBusinessGarden &&
              currentPhase?.sivaPhase !== SivaPhase['Alumni'] &&
              currentPhase?.sivaPhase !== SivaPhase['ScaleUp'] && (
                <Button
                  className="siva-contract-overview__buttons"
                  iconName="fa-light fa-arrow-right"
                  iconPosition="left"
                  size="medium"
                  theme="neutral"
                  variant="link"
                  onClick={() => setSubpage('phase')}
                >
                  {tSiva('company.details.contract.changePhase')}
                </Button>
              )}
            {sivaCoreWriteAccess && currentPhase?.sivaPhase !== SivaPhase['Alumni'] && (
              <Button
                onClick={() => setSubpage('endContract')}
                className="siva-contract-overview__buttons"
                size="medium"
                theme="neutral"
                variant="link"
              >
                <FaIcon
                  className="margin-right--1"
                  icon={icon({ prefix: 'fal', iconName: 'circle-xmark' })}
                />
                {tSiva('company.details.contract.endContract')}
              </Button>
            )}
          </div>
          <div className="margin-left--3">
            <div className="display--flex gap--3 margin-bottom--6">
              {contract && (
                <div className="flex--1">
                  <div className="margin-bottom--1">
                    <b>{tSiva('company.details.contract.overview.id')}</b>
                  </div>
                  <div className="margin-bottom--3">{contract.numericId}</div>
                  <div className="margin-bottom--1">
                    <b>{tCommon('phase')}</b>
                  </div>
                  <div className="margin-bottom--3">
                    {tSiva(`phase.${currentPhase?.sivaPhase}` as any, {
                      defaultValue: currentPhase?.sivaPhase || '',
                    })}
                  </div>
                  {currentPhase?.sivaPhase !== SivaPhase.Idea && (
                    <>
                      <div className="margin-bottom--1">
                        <b>{tCommon('industry')}</b>
                      </div>
                      <div className="margin-bottom--3">{contract.externalIndustry || tCommon('notSet')}</div>
                    </>
                  )}
                  <div className="margin-bottom--1">
                    <b>{tSiva('company.details.contract.overview.ideaName')}</b>
                  </div>
                  <div className="margin-bottom--3">{contract.name}</div>
                  <div className="margin-bottom--1">
                    <b>{tSiva('company.details.contract.overview.ideaSource')}</b>
                  </div>
                  <div>
                    {contract.ideaSource.length > 0
                      ? // @ts-expect-error IdeaSource is not well defined, so ts throws a hissy fit.
                        tSiva(`ideaSource.${contract.ideaSource}`)
                      : tSiva('company.details.contract.overview.ideaSource.none')}
                  </div>
                </div>
              )}
              <div className="flex--1">
                {contractAdmissionDate && (
                  <>
                    <div className="margin-bottom--1">
                      <b>{tSiva('company.details.contract.overview.admissionDate')}</b>
                    </div>
                    <div className="margin-bottom--3">
                      {formatDateToNOString(
                        new Date(contractAdmissionDate),
                        false,
                        '{date}.{month}.{year}',
                        true,
                      )}
                    </div>
                    <div className="margin-bottom--1">
                      <b>{tSiva('company.details.contract.overview.exitDate')}</b>
                    </div>
                    <div className="margin-bottom--3">
                      {contractEndDate
                        ? formatDateToNOString(
                            new Date(contractEndDate),
                            false,
                            '{date}.{month}.{year}',
                            true,
                          )
                        : tSiva('company.details.contract.overview.ongoingContract')}
                    </div>
                  </>
                )}

                {contractAdmissionDate && (
                  <>
                    <div className="margin-bottom--1">
                      <b>{tSiva('company.details.contract.overview.dispatchAdmissionForm')}</b>
                    </div>
                    <div className="margin-bottom--3">
                      {!!admissionFormDate &&
                        capitalizeFirstLetter(
                          formatDateToNOString(new Date(admissionFormDate), true, '{month} {year}', true),
                        )}
                    </div>
                    {!!enrouteFormDate && !(exitFormDate && enrouteFormDate > exitFormDate) && (
                      <>
                        <div className="margin-bottom--1">
                          <b>{tSiva('company.details.contract.overview.dispatchEnRouteForm')}</b>
                        </div>
                        <div>
                          {capitalizeFirstLetter(
                            formatDateToNOString(new Date(enrouteFormDate), true, '{month} {year}', true),
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}

                {!!exitFormDate && (
                  <>
                    <div className="margin-bottom--1 margin-top--3">
                      <b>{tSiva('company.details.contract.overview.dispatchExitForm')}</b>
                    </div>
                    <div>
                      {capitalizeFirstLetter(
                        formatDateToNOString(new Date(exitFormDate), true, '{month} {year}', true),
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            {contract && (
              <>
                <div className="margin-bottom--6">
                  <div className="margin-bottom--1">
                    <b>{tCommon('contactPerson')}</b>
                  </div>
                  <div className="margin-bottom--3">
                    {contract.contactFirstName} {contract.contactLastName}
                  </div>
                  <div className="margin-bottom--1">
                    <b>{tCommon('email')}</b>
                  </div>
                  <div className="margin-bottom--3">{contract.contactEmail}</div>
                </div>
                <div className="margin-bottom--10">
                  <div className="margin-bottom--1">
                    <b>{tCommon('advisor')}</b>
                  </div>
                  <div className="margin-bottom--3">{contract.advisorName}</div>
                  <div className="margin-bottom--1">
                    <b>{tCommon('note')}</b>
                  </div>
                  <div className="margin-bottom--3">{contract.notes}</div>
                </div>
                <div className="margin-bottom--5">
                  <ContractHistoric phases={contract.phases} />
                </div>
              </>
            )}
          </div>
        </div>
      );
  }
};

export default CompanyContract;
