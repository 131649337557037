import { DateInput, InputField, RadioButton, Select, Textarea } from '@in/component-library';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { SivaContractCreateDto, SivaPhase } from 'src/api/v2';
import ValueField from 'src/components/ValueField/ValueField';
import { useUsers } from 'src/features/users';
import { useExternalCompany } from 'src/hooks/use-external-company';
import { getDateOnlyString, getMonthAndYearString } from 'src/utils/date';
import { useCalculateSivaKTUDates } from 'src/hooks/use-siva-ktu-date-calculation';
import { bransjeOptions, ideaSourceOptions } from '../../constants';
import CompanyBaseInfoBox from './CompanyBaseInfoBox';
import { emailRegex } from 'src/utils/regex';
import { useTranslation } from 'react-i18next';
import useSivaAdvisorOptions from '../../hooks/use-siva-advisor-options';
import { Link } from 'react-router-dom';
import { useAuth } from 'src/features/authorization';
import { FormStepButtons } from '../FormStepButtons';

type Props = {
  organizationNumber: string;
  form: UseFormReturn<SivaContractCreateDto>;
  isSaving: boolean;
  stepForward: (() => void) | undefined;
  stepBackward: (() => void) | undefined;
};

const NewCompanyContract: React.FC<Props> = ({
  organizationNumber,
  form,
  isSaving,
  stepForward,
  stepBackward,
}) => {
  const { checkAccess } = useAuth();

  const sivaCoreAccessCheck = checkAccess('SivaCore').write;
  const isBusinessGarden = checkAccess('UI_SivaBusinessGarden').read;

  const { t: tCommon } = useTranslation();
  const { t: tSiva } = useTranslation('siva');

  const { isLoading: isLoadingUsers } = useUsers();
  const { data: company, isLoading: isLoadingCompany } = useExternalCompany(organizationNumber);

  // Dette gjør at vi kan refetch rådgivere i et intervall, etter at bruker har trykt på linken for Tilgangsstyring
  const [refetchInterval, setRefetchInterval] = useState(0);
  const REFETCH_INTERVAL = 1000 * 15;
  const startRefetchInterval = useCallback(() => setRefetchInterval(REFETCH_INTERVAL), [REFETCH_INTERVAL]);
  const { advisorOptions } = useSivaAdvisorOptions({
    refetchInterval,
  });

  const [validateMinDate, validateMaxDate] = useMemo(() => {
    const today = new Date();

    const minDate = new Date(today.getFullYear(), 0, 1);
    const maxDate = new Date(today.getFullYear(), 11, 31);

    return [minDate, maxDate];
  }, []);

  const isLoading = isLoadingUsers || isLoadingCompany;

  const { control, watch, setValue, getValues } = form;

  React.useEffect(() => {
    if (isBusinessGarden && sivaCoreAccessCheck) {
      setValue('sivaPhase', SivaPhase.Targeted);
    }
  }, [isBusinessGarden, sivaCoreAccessCheck, setValue]);

  const fromDateWatch = watch('fromDate');
  const [startupDate, midDate] = useCalculateSivaKTUDates(fromDateWatch);

  const renderFormSendingDate = (date: Date | undefined) => {
    if (!date) {
      return 'Ukjent';
    }

    return getMonthAndYearString(date);
  };

  if (isLoading) {
    return <p>{tCommon('loadingData')} ...</p>;
  }

  return (
    <div className="max-width--774px">
      <h2>{tSiva('newCompany.contract.title')}</h2>

      <CompanyBaseInfoBox company={company} />

      <h3>{form.getValues('sivaPhase') === SivaPhase.Idea ? 'Idébeskrivelse' : 'Avtaleinformasjon'}</h3>

      {sivaCoreAccessCheck && !isBusinessGarden && (
        <ValueField label={'Fase'} value={tSiva(`phase.${getValues('sivaPhase')}`)} />
      )}

      {sivaCoreAccessCheck && !isBusinessGarden && (
        <Controller
          control={control}
          name="name"
          rules={{
            required: form.getValues('organizationNumber')
              ? undefined
              : tSiva('newCompany.contract.form.name.required'),
          }}
          render={({ field, fieldState }) => (
            <InputField
              label={tSiva('newCompany.contract.form.name.label')}
              description={`${tSiva('newCompany.contract.form.name.description')} ${
                form.getValues('organizationNumber') ? '' : '*'
              }`}
              {...field}
              errorMsg={fieldState.error?.message}
            />
          )}
        />
      )}

      {form.watch('sivaPhase') === SivaPhase.Idea ? (
        <Controller
          control={control}
          name="ideaSource"
          rules={{ required: tSiva('newCompany.contract.form.ideaSource.required') }}
          render={({ field, fieldState }) => (
            <Select
              label={tSiva('newCompany.contract.form.ideaSource.label')}
              className="margin-bottom--2"
              {...field}
              options={ideaSourceOptions}
              errorMsg={fieldState.error?.message}
            />
          )}
        />
      ) : (
        <Controller
          control={control}
          name="externalIndustry"
          rules={{ required: tSiva('newCompany.contract.form.externalIndustry.required') }}
          render={({ field, fieldState }) => (
            <Select
              label={tSiva('newCompany.contract.form.externalIndustry.label')}
              helpText={tSiva('newCompany.contract.form.externalIndustry.helpText')}
              className="margin-bottom--2"
              {...field}
              options={bransjeOptions}
              errorMsg={fieldState.error?.message}
            />
          )}
        />
      )}

      <Controller
        control={control}
        name="fromDate"
        rules={{
          required: tSiva('newCompany.contract.form.fromDate.required'),
          validate: {
            sameYear: (value) => {
              if (!value) {
                return true;
              }

              const date = new Date(value);
              if (date < validateMinDate || date > validateMaxDate) {
                return tSiva('newCompany.contract.form.fromDate.sameYear');
              }

              return true;
            },
          },
        }}
        render={({ field: { value, ...field }, fieldState }) => (
          <DateInput
            label={
              form.getValues('sivaPhase') === SivaPhase.Idea
                ? 'Registreringsdato*'
                : tSiva('newCompany.contract.form.fromDate.label')
            }
            className="margin-bottom--2"
            {...field}
            errorMsg={fieldState.error?.message}
            value={value ? getDateOnlyString(value) : undefined}
          />
        )}
      />

      {form.getValues('sivaPhase') !== SivaPhase.Idea && (
        <>
          <ValueField
            label={tSiva('newCompany.contract.form.formStartupDate.label')}
            value={renderFormSendingDate(startupDate)}
          />
          <ValueField
            label={tSiva('newCompany.contract.form.formMidDate.label')}
            value={renderFormSendingDate(midDate)}
          />
        </>
      )}

      <h3 className="margin-top--8">{tSiva('newCompany.contract.section.contact.title')}</h3>
      <p>
        {form.getValues('sivaPhase') === SivaPhase.Idea
          ? 'Kontaktinformasjon til idéhaver'
          : tSiva('newCompany.contract.section.contact.description')}
      </p>

      <div className="display--grid grid-cols--2 gap-x--4">
        <Controller
          control={control}
          name="contactFirstName"
          rules={{ required: tSiva('newCompany.contract.form.contactFirstName.required') }}
          render={({ field, fieldState }) => (
            <InputField
              label={tSiva('newCompany.contract.form.contactFirstName.label')}
              {...field}
              errorMsg={fieldState.error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="contactLastName"
          rules={{ required: tSiva('newCompany.contract.form.contactLastName.required') }}
          render={({ field, fieldState }) => (
            <InputField
              label={tSiva('newCompany.contract.form.contactLastName.label')}
              {...field}
              errorMsg={fieldState.error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="contactEmail"
          rules={{
            required: tSiva('newCompany.contract.form.contactEmail.required'),
            validate: {
              email: (value) => {
                if (!value) {
                  return true;
                }
                if (!emailRegex.test(value)) {
                  return tSiva('newCompany.contract.form.contactEmail.validateEmail');
                }
              },
            },
          }}
          render={({ field, fieldState }) => (
            <InputField
              className="col-span--2"
              type="email"
              label={tSiva('newCompany.contract.form.contactEmail.label')}
              {...field}
              errorMsg={fieldState.error?.message}
            />
          )}
        />
      </div>

      <h3 className="margin-top--8">{tSiva('newCompany.contract.section.followUp.title')}</h3>

      <Controller
        control={control}
        name="advisorId"
        rules={{
          required: tSiva('newCompany.contract.form.advisorId.required'),
        }}
        render={({ field, fieldState }) => (
          <Select
            label={tSiva('newCompany.contract.form.advisorId.label')}
            {...field}
            options={advisorOptions}
            errorMsg={fieldState.error?.message}
          />
        )}
      />
      <p className="margin-top--0 margin-bottom--4">
        {tSiva('newCompany.contract.form.advisorId.link.text')}{' '}
        <Link
          to={`/actor-profile/settings/access`}
          target="_blank"
          onClick={() => {
            startRefetchInterval();
          }}
        >
          <span>{tCommon('accessControl')}</span>
        </Link>
        .
      </p>

      <Controller
        control={control}
        name="notes"
        rules={{
          maxLength: {
            value: 1000,
            message: tSiva('newCompany.contract.form.notes.maxLength'),
          },
        }}
        render={({ field, fieldState }) => (
          <Textarea
            label={tSiva('newCompany.contract.form.notes.label')}
            {...field}
            errorMsg={fieldState.error?.message}
            maxCount={1000}
          />
        )}
      />

      <h3 className="margin-top--8">{tSiva('newCompany.contract.section.women.title')}</h3>

      <Controller
        control={control}
        name="hasFemaleFounderChairMemberOrCeo"
        rules={{}}
        render={({ field, fieldState }) => (
          <RadioButton
            label={tSiva('newCompany.contract.form.hasFemaleFounderChairMemberOrCeo.label')}
            name={field.name}
            options={[
              {
                selected: field.value === true,
                text: tCommon('yes'),
                value: 'true',
              },
              {
                selected: field.value === false,
                text: tCommon('no'),
                value: 'false',
              },
            ]}
            onChange={(e) => {
              const value = e.currentTarget.value;
              if (value === 'true') {
                field.onChange(true);
              } else {
                field.onChange(false);
              }
            }}
            errorMsg={fieldState.error?.message}
          />
        )}
      />

      <FormStepButtons isLoading={isSaving} stepForward={stepForward} stepBackward={stepBackward} />
    </div>
  );
};

export default NewCompanyContract;
