import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';

import { Button, ButtonVariant, FaIcons, Stepper, Theme } from '@in/component-library';

import { StepProps } from 'src/components/Stepper';

import { ClusterContactCreateDto, ClusterContactStatus } from 'src/api/v2';

import { toastPromise } from 'src/utils/toast';

import ContactAccordion from '../../common/ContactAccordion';

import useCluster from 'src/features/cluster/hooks/use-cluster';
import useMembers from 'src/hooks/use-members';
import { useContacts } from 'src/features/contacts';

import { CreateMemberContext } from './CreateMemberModal';
import hotjarEvent from 'src/utils/hotjarEvent';

type FormData = {
  contacts: ClusterContactCreateDto[];
  hidden?: string;
};

type Props = StepProps & {
  onClose: () => void;
};

export const Step3: React.FC<Props> = ({ onClose, goPreviousStep }) => {
  const { t: tCommon } = useTranslation();
  const { t: tError } = useTranslation('error');
  const { t: tButtons } = useTranslation('buttons');
  const { t: tResourceBase } = useTranslation('resourceBase');

  const context = React.useContext(CreateMemberContext);

  const { cluster } = useCluster();
  const { createMutation } = useMembers();
  const { createListMutation } = useContacts();

  const [activeAccordion, setActiveAccordion] = React.useState<number>(0);

  const isSubmitting = useMemo(
    () => createMutation.isLoading || createListMutation.isLoading,
    [createListMutation.isLoading, createMutation.isLoading],
  );

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      contacts: [{}],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  });

  const watchFields = watch('contacts');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFields[index],
    };
  });

  const onSubmit = handleSubmit(async (data) => {
    if (!cluster) {
      return;
    }

    // This is only for cases where contacts is blank/empty
    data.hidden = undefined;

    if (context.member) {
      context.member.clusterId = cluster.id;

      const clusterMemberPromise = createMutation.mutateAsync(context.member);

      toastPromise(clusterMemberPromise, {
        error: tError('member.couldNotCreate'),
      }).then((response) => {
        const clusterMemberPayload = response;

        // check if we want to make any contacts
        if (data.contacts && data.contacts.length > 0) {
          const contacts: ClusterContactCreateDto[] = [...data.contacts].map((contact) => {
            return {
              ...contact,
              clusterId: cluster.id,
              status: ClusterContactStatus.Active,
              isDefaultCluster: true,
              interestAreaIds: [],
              clusterMemberId: clusterMemberPayload.id,
            };
          });

          // const clusterContactPromise = dispatch(createContactList(contacts));
          const clusterContactPromise = createListMutation.mutateAsync(contacts);

          toastPromise(clusterContactPromise, {
            error: tError('contact.couldNotCreate'),
          }).then(() => {
            onClose();
            hotjarEvent('member created');
          });
        } else {
          onClose();
        }
      });
    } else {
      toast.error(tError('company.infoNotFound'));
    }
  });

  React.useEffect(() => {
    if (context.contacts.length > 0) {
      reset({ contacts: context.contacts });
    }
  }, [context.contacts, reset]);

  return (
    <div>
      <Stepper currentStep={2} stepCount={3} />

      <p>{tResourceBase('contactList.description')}</p>

      <form onSubmit={onSubmit}>
        <div className="display--flex gap--2 margin-bottom--3 justify-content--space-between">
          <Button
            type="button"
            theme={Theme.Neutral}
            variant={ButtonVariant.Outlined}
            iconName={FaIcons.PlusLight}
            onClick={() => {
              if (!isSubmitting) {
                // @ts-ignore
                //TODO: objekt stemmer ikke overens med forventet model, gir feil
                append({});
                setActiveAccordion(fields.length);
              }
            }}
          >
            {tButtons('addContact')}
          </Button>
          <Button
            type="button"
            theme={Theme.Danger}
            variant={ButtonVariant.Outlined}
            onClick={() => {
              if (!isSubmitting) {
                context.contacts = [];
                reset({ contacts: [] });
              }
            }}
          >
            {tResourceBase('removeAllContactsFromList')}
          </Button>
        </div>

        <input type="hidden" {...register('hidden')} value="hidden" />

        <div className="margin-bottom--3">
          {controlledFields.map((field, index) => {
            let title: React.ReactNode = tResourceBase('newContact');

            const firstName = field?.firstName;
            const lastName = field?.lastName;

            if (firstName || lastName) {
              title = `${firstName || ''} ${lastName || ''}`;
            }

            if (errors.contacts && errors.contacts[index]) {
              const hasErrors =
                errors.contacts[index]?.firstName ||
                errors.contacts[index]?.lastName ||
                errors.contacts[index]?.emailAddress;

              if (hasErrors) {
                title = <span style={{ color: '#ed0000' }}>{title}</span>;
              }
            }

            return (
              <ContactAccordion
                key={index}
                title={title}
                open={index === activeAccordion}
                index={index}
                register={register}
                fields={fields}
                field={field}
                errors={errors}
                remove={remove}
                isSubmitting={isSubmitting}
                clusterId={cluster!.id}
              />
            );
          })}
        </div>

        <div className="display--flex gap--2 align-items--center justify-content--space-between">
          <Button
            type="button"
            theme={Theme.Neutral}
            variant={ButtonVariant.Outlined}
            disabled={isSubmitting}
            onClick={() => {
              context.contacts = controlledFields;
              goPreviousStep();
            }}
          >
            {tCommon('previous')}
          </Button>

          <Button type="submit" theme={Theme.Positive} isLoading={isSubmitting}>
            {tResourceBase('createMemberCompany')}
          </Button>
        </div>
      </form>
    </div>
  );
};
