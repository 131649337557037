import React, { ReactNode } from 'react';
import './TertiaryButton.scss';
import { FaIcon, FaIcons } from '@in/component-library';
import classNames from 'classnames';

// Sizes available in utilities.scss
type InSize =
  | 'x-small'
  | 'small'
  | 'medium'
  | 'large'
  | 'x-large'
  | 'xx-large'
  | '2rem'
  | '20px'
  | '40px'
  | '3rem';

function TertiaryButton(props: {
  children?: ReactNode;
  icon?: FaIcons;
  onClick?: () => void;
  standardizedSize?: InSize | false;
  minimalPadding?: boolean;
  className?: string;
  ariaLabel?: string;
}) {
  const fontSize = props.standardizedSize ?? 'medium';
  return (
    <button
      className={classNames(
        'tertiary-button',
        fontSize && `font-size--${fontSize}`,
        props.minimalPadding && 'minimal-padding',
        props.className,
      )}
      aria-label={props.ariaLabel}
      type="button"
      onClick={props.onClick}
    >
      <span>
        {props.icon && (
          <FaIcon
            name={props.icon}
            // The size should match the fontSize of the button, not be overriden by FaIcon logic
            // This is a workaround as FaIcon forces a static font-size
            size={'none' as any}
          />
        )}
        {props.children}
      </span>
    </button>
  );
}

export default TertiaryButton;
