import React, { useContext } from 'react';

import { Button, FaIcon, FaIcons, ListLink } from '@in/component-library';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

import '../styles/HelpAndSupportMenu.scss';
import { useTranslation } from 'react-i18next';
import HelpAndSupportMenuContent from './HelpAndSupportMenuContent';
import { SideMenuSubpageContext } from './OnboardingSideMenu';
import { useOnboarding } from '../hooks.tsx/use-onboarding';

type Props = {
  minimized?: boolean;
};

const HelpAndSupportMenu: React.FC<Props> = ({ minimized }) => {
  const { onboardingPackets } = useOnboarding();
  const { t: tHelpAndSupport } = useTranslation('onboarding');

  const { subpage, setSubpage, setFlow } = useContext(SideMenuSubpageContext);
  if (!setSubpage) return <></>;

  if (minimized) {
    return (
      <div className="display--flex flex-direction--column text-align--center">
        <FaIcon icon={icon({ prefix: 'far', iconName: 'message-question' }) as IconDefinition} size="lg" />
        <p>{tHelpAndSupport('helpAndSupport.Header')}</p>
      </div>
    );
  }

  const handleReturnToStart = () => {
    setSubpage('none');
    setFlow?.(null);
  };

  if (subpage !== 'none') {
    return (
      <div className="display--flex flex-direction--column width--100">
        <div className="margin-bottom--2">
          <Button
            type="button"
            theme="neutral"
            variant="link"
            onClick={handleReturnToStart}
            iconName={FaIcons.ArrowLeft}
          >
            {tHelpAndSupport('helpAndSupport.Back')}
          </Button>
        </div>
        <div>
          <HelpAndSupportMenuContent subpage={subpage} />
        </div>
      </div>
    );
  }

  return (
    <div className="display--flex flex-direction--column width--100">
      <div className="display--flex align-items--center gap--2 margin-bottom--6">
        <FaIcon icon={icon({ prefix: 'far', iconName: 'message-question' }) as IconDefinition} size="2x" />
        <h1 className="help-and-support__header">{tHelpAndSupport('helpAndSupport.Header')}</h1>
      </div>
      <>
        {onboardingPackets.length > 0 && (
          <ListLink
            className="help-and-support-sl-outline-fixer cursor--pointer"
            header={tHelpAndSupport('helpAndSupport.tab.Startup')}
            onClick={() => setSubpage('stepConfirmation')}
            onKeyDown={(e) => e.code === 'Enter' && setSubpage('stepConfirmation')}
            tabIndex={0}
          >
            {tHelpAndSupport('helpAndSupport.tab.Startup.Helptext')}
          </ListLink>
        )}
        <ListLink
          className="help-and-support-sl-outline-fixer cursor--pointer"
          header={tHelpAndSupport('helpAndSupport.tab.CommonQuestions')}
          onClick={() => setSubpage('commonQuestions')}
          onKeyDown={(e) => e.code === 'Enter' && setSubpage('commonQuestions')}
          tabIndex={0}
        ></ListLink>
        <ListLink
          className="help-and-support-sl-outline-fixer cursor--pointer"
          header={tHelpAndSupport('helpAndSupport.tab.ContactUs')}
          onClick={() => setSubpage('contactUs')}
          onKeyDown={(e) => e.code === 'Enter' && setSubpage('contactUs')}
          tabIndex={0}
        ></ListLink>
      </>
    </div>
  );
};

export default HelpAndSupportMenu;
