import { Button, ButtonVariant, FaIcons, IconButton } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import Table from 'src/components/Table';
import { useBoolean } from 'usehooks-ts';
import type { DataTableColumnEditorProps } from '../types';
import classNames from 'classnames';

// There is only "__selection" that has the prefix as of now, but it can be used later
// to add more columns that should not be moved
const UNMOVABLE_COLUMN_PREFIX = '__';

const SELECTION_COLUMN_ID = `${UNMOVABLE_COLUMN_PREFIX}selection`;

const DataTableColumnEditor = <TData extends object>({
  table,
  disabled,
  disableSelection,
}: DataTableColumnEditorProps<TData>) => {
  const { t: tCommon } = useTranslation();
  const { t: tTableTitles } = useTranslation('tableTitles');

  const showModal = useBoolean();

  const columns = table.getAllLeafColumns();
  // Filter out columns that should not be moved, their id starts with "__", such as "__selection"
  // "__selection" is the column for selecting rows, it should always be the first column
  const movableColumns = columns.filter((column) => !column.id.startsWith(UNMOVABLE_COLUMN_PREFIX));

  const showAll = () => {
    table.toggleAllColumnsVisible(true);
  };

  const reset = () => {
    table.resetColumnOrder();
    table.resetColumnVisibility();
  };

  const swap = (input: string[], a: number, b: number): string[] => {
    [input[a], input[b]] = [input[b], input[a]];

    return input;
  };

  const promote = (columnId: string) => {
    const columnOrder = movableColumns.map((column) => column.id);
    const index = columnOrder.indexOf(columnId);
    const newOrder = swap(columnOrder, index, index - 1);

    if (disableSelection) {
      table.setColumnOrder(newOrder);
    } else {
      table.setColumnOrder([SELECTION_COLUMN_ID, ...newOrder]);
    }
  };

  const demote = (columnId: string) => {
    const columnOrder = movableColumns.map((column) => column.id);
    const index = columnOrder.indexOf(columnId);
    const newOrder = swap(columnOrder, index, index + 1);

    if (disableSelection) {
      table.setColumnOrder(newOrder);
    } else {
      table.setColumnOrder([SELECTION_COLUMN_ID, ...newOrder]);
    }
  };

  if (disabled) {
    return null;
  }

  return (
    <>
      <Button
        type="button"
        iconName={FaIcons.EditLight}
        variant={ButtonVariant.Link}
        onClick={showModal.setTrue}
      >
        {tTableTitles('editTabelView')}
      </Button>

      <DialogContainer
        title={tTableTitles('editTabelView')}
        show={showModal.value}
        onClose={showModal.setFalse}
      >
        <Table className="margin-bottom--2">
          <Table.Head>
            <Table.Row>
              <Table.Header>{tCommon('show')}</Table.Header>
              <Table.Header>{tCommon('column')}</Table.Header>
              <Table.Header>{tCommon('move')}</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {movableColumns.map((column, index) => {
              const canPromote = index > 0;
              const canDemote = index < movableColumns.length - 1;
              const canHide = column.getCanHide();
              const isVisible = column.getIsVisible();

              return (
                <Table.Row key={column.id}>
                  <Table.Cell>
                    <label>
                      <input
                        {...{
                          type: 'checkbox',
                          checked: isVisible,
                          onChange: column.getToggleVisibilityHandler(),
                          className: 'square--20px',
                          disabled: canHide === false,
                          title:
                            canHide === false
                              ? tCommon('cannotHideColumn' as any)
                              : isVisible
                                ? tCommon('hideColumn' as any)
                                : tCommon('showColumn' as any),
                        }}
                      />
                      <span className="sr-only">{tTableTitles(column.id as any, column.id)}</span>
                    </label>
                  </Table.Cell>
                  <Table.Cell>
                    {tTableTitles(column.id as any, {
                      defaultValue: column.id,
                    })}
                  </Table.Cell>
                  <Table.Cell>
                    <div className="display--flex align-items--center gap--2">
                      <IconButton
                        {...{
                          iconName: FaIcons.CaretUp,
                          disabled: !canPromote,
                          className: classNames({
                            'color--gray-100': !canPromote,
                          }),
                          onClick: () => promote(column.id),
                          'aria-label': tCommon('moveUp'),
                          title: tCommon('moveUp'),
                        }}
                      />
                      <IconButton
                        {...{
                          iconName: FaIcons.CaretDown,
                          disabled: !canDemote,
                          className: classNames({
                            'color--gray-100': !canDemote,
                          }),
                          onClick: () => demote(column.id),
                          'aria-label': tCommon('moveDown'),
                          title: tCommon('moveDown'),
                        }}
                      />
                    </div>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>

        <div className="display--flex justify-content--space-between">
          <Button
            className="padding-left--2 padding-right--2"
            type="button"
            iconName={FaIcons.Eye}
            variant={ButtonVariant.Link}
            onClick={showAll}
          >
            {tCommon('showAllColumns')}
          </Button>
          <Button
            className="padding-left--2 padding-right--2"
            type="button"
            iconName={FaIcons.SyncLight}
            variant={ButtonVariant.Link}
            onClick={reset}
          >
            {tCommon('reset')}
          </Button>
        </div>
      </DialogContainer>
    </>
  );
};

export default DataTableColumnEditor;
