import { SivaContractOverviewDto, SivaPhase } from '../../../../api/v2';
import React, { useMemo, useState } from 'react';
import { Tabs } from '@in/component-library';
import { ColumnDef } from '@tanstack/react-table';
import {
  admissionDateColumn,
  advisorColumn,
  companyNameOrIdeaNameColumn,
  ideaNameColumn,
  numericIdColumn,
  phaseColumn,
  registrationDateColumn,
} from './use-siva-overview-columns';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

interface TabDefinition {
  name: 'targetCompanies' | 'ideas' | 'contracts';
  availablePhases: SivaPhase[];
  columns: ColumnDef<SivaContractOverviewDto, any>[];
}

const targetedTab = {
  name: 'targetCompanies' as const,
  availablePhases: [SivaPhase.Targeted],
  columns: [companyNameOrIdeaNameColumn, numericIdColumn, admissionDateColumn, advisorColumn],
};
const ideasTab = {
  name: 'ideas' as const,
  availablePhases: [SivaPhase.Idea],
  columns: [ideaNameColumn, numericIdColumn, advisorColumn, registrationDateColumn],
};
const contractsTab = {
  name: 'contracts' as const,
  availablePhases: [
    SivaPhase.PreIncubation,
    SivaPhase.Incubated,
    SivaPhase.Fi,
    SivaPhase.ScaleUp,
    SivaPhase.Alumni,
  ],
  columns: [companyNameOrIdeaNameColumn, phaseColumn, numericIdColumn, admissionDateColumn, advisorColumn],
};

export const useSivaOverviewTabs = (isBusinessGarden: boolean) => {
  const { t: tSiva } = useTranslation('siva');

  const tabs: TabDefinition[] = useMemo(
    () => (isBusinessGarden ? [targetedTab] : [contractsTab, ideasTab]),
    [isBusinessGarden],
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const initialState = tabs.find((tab) => tab.name === searchParams.get('tab'));
  const [activeTabName, setActiveTabName] = useState(initialState?.name || tabs[0].name);

  const activeTab = useMemo(
    () => tabs.find((t) => t.name === activeTabName) ?? tabs[0],
    [activeTabName, tabs],
  );

  const handleChangeTab = (tabIndex: number) => {
    setActiveTabName(tabs[tabIndex].name);
    setSearchParams({ tab: tabs[tabIndex].name }, { replace: true });
  };

  const tabElements = (
    <Tabs
      className="margin-top--6"
      setActiveTab={(tabIndex: number) => handleChangeTab(tabIndex)}
      tabs={tabs.map((tab) => ({
        'aria-label': tSiva(`overviewTabs.${tab.name}`),
        'aria-selected': tab === activeTab,
      }))}
    />
  );

  return {
    tabElements,
    availablePhasesForTab: activeTab.availablePhases,
    columnsForTab: activeTab.columns,
  };
};
