import React from 'react';
import { useCluster } from 'src/features/cluster';
import useOneWayInVMA from '../hooks/use-one-way-in-vma';
import '../styles/OneWayInStyle.scss';
import OneWayInReviewOpportunities from '../components/review/OneWayInReviewOpportunities';

const OneWayInActorOpportunities: React.FC = () => {
  const { cluster } = useCluster();

  const actorName = cluster!.oneWayInVmaNames[0];

  const [orderFromOldToNew, setOrderFromOldToNew] = React.useState<boolean>(false);
  const [fromDate, setFromDate] = React.useState<Date | undefined>(undefined);
  const [toDate, setToDate] = React.useState<Date | undefined>(undefined);

  const { oneWayInAllVMAFormsQuery, oneWayInFeedbackListFromActorQuery } = useOneWayInVMA(
    actorName,
    orderFromOldToNew,
    fromDate,
    toDate,
  );

  return (
    <OneWayInReviewOpportunities
      listQuery={oneWayInAllVMAFormsQuery}
      feedbackListQuery={oneWayInFeedbackListFromActorQuery}
      fromList={[]}
      setFromDate={setFromDate}
      setToDate={setToDate}
      orderFromOldToNew={{ init: orderFromOldToNew, set: setOrderFromOldToNew }}
    />
  );
};

export default OneWayInActorOpportunities;
