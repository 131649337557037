import { useMemo } from 'react';

import useStatusPrograms from './use-status-programs';

export function useSupportEmail() {
  const { isProgramOneWayIn, isProgramSiva } = useStatusPrograms();

  const userUrl = window.location.href;
  const userBrowser = navigator.userAgent;

  const email = useMemo(() => {
    if (isProgramSiva) {
      return 'support@siva.no';
    }

    if (isProgramOneWayIn) {
      return 'support@enveiinn.no';
    }

    return 'klyngeportalen@innovasjonnorge.no';
  }, [isProgramOneWayIn, isProgramSiva]);

  const mailto = useMemo(() => {
    if (isProgramSiva) {
      return `mailto:support@siva.no?subject=Rapportering%20til%20SIVA&body=%0D%0A%0D%0AURL%3A%20${userUrl}%0D%0ANettleser%3A%20${userBrowser}`;
    }

    if (isProgramOneWayIn) {
      return `mailto:support@enveiinn.no?subject=En%20vei%20inn&body=%0D%0A%0D%0AURL%3A%20${userUrl}%0D%0ANettleser%3A%20${userBrowser}`;
    }

    return `mailto:klyngeportalen@innovasjonnorge.no?subject=Klynge&body=%0D%0A%0D%0AURL%3A%20${userUrl}%0D%0ANettleser%3A%20${userBrowser}`;
  }, [isProgramSiva, isProgramOneWayIn, userUrl, userBrowser]);

  return {
    email,
    mailto,
  };
}
