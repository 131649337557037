import React, { useMemo, useState } from 'react';
import { SivaContractOverviewDto, SivaPhase } from 'src/api/v2';
import DataTableV3 from 'src/components/DataTableV3';
import { DataTableV3Ids } from 'src/constants/dataTableIds';
import { Select } from '@in/component-library';
import { useTranslation } from 'react-i18next';
import { useSivaContracts } from '../hooks/use-siva-contracts';
import { ColumnDef } from '@tanstack/react-table';
import { advisorColumnAccessor } from '../hooks/use-siva-overview-columns';
import styles from './SivaContractOverview.module.scss';
import ResetFilterButton from 'src/components/ResetFilterButton/ResetFilterButton';

type Props = {
  availablePhases: SivaPhase[];
  columns: ColumnDef<SivaContractOverviewDto>[];
};

const SivaContractOverviewDataTable: React.FC<Props> = ({ columns, availablePhases }) => {
  const { columnFilters, filtersElement, contracts } = useContractsWithFilters({ availablePhases });

  return (
    <DataTableV3
      disableColumnEditor={true}
      id={DataTableV3Ids.Siva.IdeasAndCompanies.ContractsOverview}
      data={contracts}
      pageSizeOptions={[25, 50, 100, 250, 500]}
      pageSize={100}
      columns={columns}
      sorting={[
        {
          id: 'numericId',
          desc: true,
        },
      ]}
      columnFilters={columnFilters}
      customColumnFilters={filtersElement}
      hideFilterText
    />
  );
};

interface useContractsWithFiltersParams {
  availablePhases: SivaPhase[];
}

const useContractsWithFilters = ({ availablePhases }: useContractsWithFiltersParams) => {
  const { t: tCommon } = useTranslation();
  const { t: tSiva } = useTranslation('siva');

  const phaseFilterOptions = useMemo(
    () => [
      {
        value: '',
        text: tSiva('choose.phase'),
      },
      ...availablePhases.map((phase) => ({
        value: phase,
        text: tSiva(`phase.${phase}` as any, {
          defaultValue: phase,
        }),
      })),
    ],
    [availablePhases, tSiva],
  );

  const [ideaFilter, setIdeaFilter] = useState<SivaPhase | ''>('');

  const { contracts } = useSivaContracts({
    sivaPhases: ideaFilter && availablePhases.includes(ideaFilter) ? [ideaFilter] : availablePhases,
  });

  const advisorOptions = useMemo(() => {
    const sortedUniqueNames = [...new Set(contracts.map(advisorColumnAccessor))].sort();
    return [
      {
        value: '',
        text: tSiva('choose.advisor'),
      },
      ...sortedUniqueNames.map((value) => ({
        value,
        text: value,
      })),
    ];
  }, [contracts, tSiva]);
  const [advisorFilter, setAdvisorFilter] = useState<string>('');

  const filtersElement = (
    <div className={styles.filters}>
      {availablePhases.length > 1 && (
        <Select
          name="idea-filter"
          label={tCommon('phase')}
          value={ideaFilter}
          options={phaseFilterOptions}
          onChange={(value) => setIdeaFilter(value as SivaPhase)}
          hideError={true}
        />
      )}
      <Select
        name="advisor-filter"
        label={tCommon('advisor')}
        value={advisorFilter}
        options={advisorOptions}
        onChange={setAdvisorFilter}
        hideError={true}
      />

      <ResetFilterButton
        onClick={() => {
          setIdeaFilter('');
          setAdvisorFilter('');
        }}
        className={styles.resetButton}
      />
    </div>
  );
  return {
    filtersElement,
    columnFilters: [{ id: 'advisor', value: advisorFilter }],
    contracts,
  };
};

export default SivaContractOverviewDataTable;
