export const APPLICATION_STATITICS_KEY = 'application-statistics';
export const AUTH_KEY = 'auth';

export const ACTIVE_OPERATION_APPLICATION_KEY = 'active-operation-aplication';

export const B2C_INFO_KEY = 'b2c-info';

export const CLUSTER_KEY = 'cluster';
export const CLUSTERS_KEY = 'clusters';
export const PROGRAM_CLUSTERS_KEY = 'program-clusters';
export const CLUSTER_LOGO_KEY = 'cluster-logo';
export const STATUS_PROGRAMS_KEY = 'status-programs';
export const CONTACT_KEY = 'contact';
export const CONTACTS_KEY = 'contacts';
export const CONTACTS_BY_MEMBER_KEY = 'contacts-by-member';
export const CURRENT_CONTACT_KEY = 'current-contact';
export const CLUSTER_STATISTICS_KEY = 'cluster-statistics';

export const COURSES_KEY = 'courses';
export const COURSE_SEND_RECOMMENDATION_KEY = 'course-send-recommendation';
export const COURSE_RECOMMENDATION_LIST_KEY = 'course-recommendation-list';

export const D365_COURSE_KEY = 'd365-course';
export const D365_COURSES_LIST_KEY = 'd365-courses';

export const CORE_MEMBER_TIME_ENTRIES_KEY = 'core-member-time-entries';

export const ENVIRONMENT_INFORMATION_KEY = 'environment-information';

export const EVENT_KEY = 'event';
export const EVENT_DETAILS_KEY = 'event-details';
export const EVENTS_KEY = 'events';
export const EVENT_TIME_ENTRIES_KEY = 'event-time-entries';
export const EVENT_PARTICIPANTS_KEY = 'event-participants';

export const FINANCING_SOURCES_KEY = 'financing-sources';
export const FOCUS_AREA_KEY = 'focus-area';
export const FOCUS_AREAS_KEY = 'focus-areas';

export const INTEREST_AREAS_KEY = 'interest-areas';

export const MAIN_GOAL_KEY = 'main-goal';
export const MAIN_GOALS_KEY = 'main-goals';
export const MEMBER_CATEGORIES_KEY = 'member-categories';
export const MEMBER_FEES_KEY = 'member-fees';
export const MEMBER_TYPES_KEY = 'member-types';
export const MEMBER_KEY = 'member';
export const MEMBERS_KEY = 'members';
export const MEMBER_CONTACTS_KEY = 'member-contacts';
export const MEMBER_ECONOMY_KEY = 'member-economy';
export const MEMBER_TIME_ENTRIES_KEY = 'member-time-entries';
export const MEMBER_FILES_KEY = 'member-files';
export const MEMBER_FILES_LOGO_KEY = 'member-files-logo';

export const ONBOARDING_PACKETS_KEY = 'onboarding-packets';

export const OPERATION_APPLICATION_STATISTICS_KEY = 'operation-application-statistics';
export const OPERATION_APPLICATION_STATISTICS_BY_ID_KEY = 'operation-application-statistics-by-id';
export const OPERATION_TIME_ENTRIES_KEY = 'operation-time-entries';
export const OPERATION_TIME_ENTRIES_BY_ID_KEY = 'operation-time-entries-by-id';
export const OPERATION_APPLICATIONS_KEY = 'operation-applications';
export const OPERATION_APPLICATION_BY_ID_KEY = 'operation-application-by-id';

export const OPERATION_HALF_YEAR_REPORTS_KEY = 'operation-half-year-reports';
export const OPERATION_HALF_YEAR_REPORT_DRAFT_KEY = 'operation-half-year-report-draft';
export const OPERATION_HALF_YEAR_GENERAL_INFO_KEY = 'operation-half-year-general-info';
export const OPERATION_HALF_YEAR_ECONOMY_INFO_KEY = 'operation-half-year-economy-info';

export const OPERATION_FINAL_REPORTS_KEY = 'operation-final-reports';
export const OPERATION_FINAL_REPORT_DRAFT_KEY = 'operation-final-report-draft';
export const OPERATION_FINAL_GENERAL_INFO_KEY = 'operation-final-general-info';
export const OPERATION_FINAL_ECONOMY_INFO_KEY = 'operation-final-economy-info';
export const OPERATION_FINAL_REPORT_AS_PDF_KEY = 'operation-final-report-as-pdf';

export const BASIS_HALF_YEAR_REPORTS_KEY = 'basis-half-year-reports';
export const BASIS_HALF_YEAR_REPORT_DRAFT_KEY = 'basis-half-year-report-draft';
export const BASIS_HALF_YEAR_GENERAL_INFO_KEY = 'basis-half-year-general-info';
export const BASIS_HALF_YEAR_REPORT_AS_PDF_KEY = 'basis-interim-report-as-pdf';

export const BASIS_FINAL_REPORTS_KEY = 'basis-final-reports';
export const BASIS_FINAL_REPORT_DRAFT_KEY = 'basis-final-report-draft';
export const BASIS_FINAL_GENERAL_INFO_KEY = 'basis-final-general-info';
export const BASIS_FINAL_REPORT_AS_PDF_KEY = 'basis-final-report-as-pdf';

export const DEVELOPMENT_HALF_YEAR_REPORTS_KEY = 'development-half-year-reports';
export const DEVELOPMENT_HALF_YEAR_REPORT_DRAFT_KEY = 'development-half-year-report-draft';
export const DEVELOPMENT_HALF_YEAR_GENERAL_INFO_KEY = 'development-half-year-general-info';
export const DEVELOPMENT_HALF_YEAR_ECONOMY_INFO_KEY = 'development-half-year-economy-info';
export const DEVELOPMENT_REPORT_AS_DOCUMENT_KEY = 'development-report-as-document';

export const DEVELOPMENT_FINAL_REPORTS_KEY = 'development-final-reports';
export const DEVELOPMENT_FINAL_REPORT_DRAFT_KEY = 'development-final-report-draft';
export const DEVELOPMENT_FINAL_GENERAL_INFO_KEY = 'development-final-general-info';
export const DEVELOPMENT_FINAL_ECONOMY_INFO_KEY = 'development-final-economy-info';
export const DEVELOPMENT_FINAL_REPORT_AS_DOCUMENT_KEY = 'development-final-report-as-document';

export const ANNUAL_REPORTS_KEY = 'annual-reports';
export const ANNUAL_REPORT_DRAFT_KEY = 'annual-report-draft';
export const ANNUAL_GENERAL_INFO_KEY = 'annual-general-info';
export const ANNUAL_REPORT_AS_PDF_KEY = 'annual-report-as-pdf';

export const PROJECT_ACTIVITIES_KEY = 'project-activities';
export const PROJECTS_KEY = 'projects';
export const DEV_PROJECTS_KEY = 'development-projects';
export const PORTAL_PROJECTS_KEY = 'portal-projects';
export const OPERATION_PROJECTS_KEY = 'operation-projects';
export const PROJECTS_ONGOING_KEY = 'projects-ongoing';
export const PROJECT_PROPERTY_TYPES_KEY = 'project-property-types';
export const PROJECT_CATEGORY_KEY = 'project-category';
export const PROJECT_CATEGORIES_KEY = 'project-categories';
export const POSTAL_CODE_KEY = 'postal-code';
export const PROJECT_DETAIL_KEY = 'project-detail';
export const PROJECT_DETAIL_ACITIVITES_KEY = 'project-detail-activities';
export const PROJECT_DETAIL_EXPENSES_KEY = 'project-detail-expenses';
export const PROJECT_DETAIL_FINANCES_KEY = 'project-detail-finances';
export const PROJECT_DETAIL_MEMBERS_KEY = 'project-detail-members';
export const PROJECT_DETAIL_EXPENSE_TYPES_KEY = 'project-detail-expense-types';
export const PROJECT_STATISTICS_KEY = 'project-statistics';
export const DEVELOPMENT_PROJECT_STATISTICS_KEY = 'development-project-statistics';
export const OPERATION_PROJECT_STATISTICS_KEY = 'operation-project-statistics';
export const PORTAL_PROJECT_STATISTICS_KEY = 'portal-project-statistics';

export const REPORT_AS_PDF_KEY = 'report-as-pdf';

export const TIME_ENTRIES_KEY = 'time-entries';
export const TIME_RATES_KEY = 'time-rates';

export const USER_KEY = 'user';
export const USER_ROLE_KEY = 'user-role';
export const USER_STAUTS_LOGGED_IN = 'user-status-logged-in';
export const USER_STATUS_LOADING = 'user-status-loading';
export const USER_STATUS_REGISTERED = 'user-status-registered';
export const USER_STATUS_HAS_CLUSTERS = 'user-status-has-clusters';
export const USER_STATUS_HAS_ACCEPTED_TERMS = 'user-status-has-accepted-terms';
export const USER_STATUS_HAS_LOGIN_ERROR_4XX = 'user-status-has-login-error-4XX';
export const USER_STATUS_HAS_LOGIN_ERROR_5XX = 'user-status-has-login-error-5XX';
export const USER_SESSION_HAS_EXIRED = 'user-session-has-exipred';
export const USER_FILES_LOGO_KEY = 'user-files-logo';

export const WORK_GROUPS_KEY = 'work-groups';
export const WORK_GROUP_KEY = 'work-group';

export const ARCHIVED_BASIS_HALF_YEAR_REPORTS_KEY = 'archived-basis-half-year-reports';
export const ARCHIVED_OPERATION_HALF_YEAR_REPORTS_KEY = 'archived-operation-half-year-reports';
export const ARCHIVED_DEVELOPMENT_HALF_YEAR_REPORTS_KEY = 'archived-development-half-year-reports';

export const ARCHIVED_BASIS_FINAL_REPORTS_KEY = 'archived-basis-final-reports';
export const ARCHIVED_OPERATION_FINAL_REPORTS_KEY = 'archived-operation-final-reports';
export const ARCHIVED_DEVELOPMENT_FINAL_REPORTS_KEY = 'archived-development-final-reports';

export const ARCHIVED_ANNUAL_REPORTS_KEY = 'archived-annual-reports';

export const ATTACHMENT_AS_PDF_KEY = 'attachment-as-pdf';
export const GRANTS_KEY = 'grants-key';
export const ONE_WAY_IN_ALL_FORMS_KEY = 'one-way-in-all-forms';
export const ONE_WAY_IN_ALL_SAVED_FORMS_KEY = 'one-way-in-all-forms-saved';
export const ONE_WAY_IN_FORMS_BY_MUNICIPALITY_KEY = 'one-way-in-forms-by-municipality';
export const ONE_WAY_IN_GROUPED_OPPORTUNITY_FORM = 'one-way-in-grouped-opportunity-form';
export const ONE_WAY_IN_GROUPED_FORM_WITHOUT_SAVE = 'one-way-in-grouped-form-without-save';
export const ONE_WAY_IN_SERVICES = 'one-way-in-services';
export const ONE_WAY_IN_SERVICES_BY_ID = 'one-way-in-services-id';
export const ONE_WAY_IN_SERVICE_PROVIDERS = 'one-way-in-service-providers';
export const ONE_WAY_IN_FORM_FULL_HISTORY = 'one-way-in-form-full-history';
export const ONE_WAY_IN_FORM_IN_RESPONSE_MAIL = 'one-way-in-form-in-response-mail';
export const ONE_WAY_IN_FEEDBACK = 'one-way-in-feedback';
export const ONE_WAY_IN_FEEDBACK_LIST = 'one-way-in-feedback-list';
export const ONE_WAY_IN_OPPORTUNITY_FORM_LINK = 'one-way-in-opportunity-form-link';
export const GEONORGE_MUNICIPALITIES = 'geonorge-municipalities';

export const ONE_WAY_IN_VMA_ALL = 'one-way-in-all-vmas';
export const ONE_WAY_IN_VMA_FORMS = 'one-way-in-vma-forms';

export const AGGREGATED_ANNUAL_REPORT_KEY = 'aggregated-annual-report-key';

export const BRREG_FETCH_KEY = 'brreg-fetch-by-organizationnumber';
export const BRREG_ROLLER_KEY = 'brreg-roller';
export const BRREG_SEARCH_KEY = 'brreg-search';

export const ROLES_KEY = 'roles';

export const CLUSTER_USERS_KEY = 'cluster-users';
export const MY_CLUSTER_USER_KEY = 'my-cluster-user';
export const EXTERNAL_SERVICE_PROVIDER_KEY = 'my-cluster-user';

export const SIVA_CLUSTER_DETAILS_KEY = 'siva-cluster-details';
export const SIVA_GOAL_ACCOMPLISHMENT_KEY = 'siva-goal-accomplishment';
export const SIVA_BUDGET_ATTACHMENTS_KEY = 'siva-budget-attachments';

export const SIVA_GOALS_DETAILS_KEY = 'siva-goals-details';
export const SIVA_GOALS_DETAILS_LIST_KEY = 'siva-goals-details-list';
export const SIVA_GOALS_TARGET_NUMBERS_KEY = 'siva-goals-target-numbers';
export const SIVA_MANAGEMENT_BUDGET_YEAR = 'siva-management-budget-year';
export const SIVA_BUDGET_DETAILS_LIST_KEY = 'siva-budget-details-list';

export const SIVA_COMPANY_DETAILS_CONTRACTS_KEY = 'siva-company-details-contracts-key';
export const SIVA_COMPANY_DETAILS_CONTRACT_KEY = 'siva-company-details-contract-key';
export const SIVA_COMPANY_DETAILS_ESA_BILLS_KEY = 'siva-esa-bills';
export const SIVA_COMPANY_ESA_COMMON_REPORT = 'siva-common-esa-report';
export const SIVA_INNOVATION_FUNDS_KEY = 'siva-innovation-funds';
export const SIVA_ADMIN_ESA_KEY = 'siva-esa-bills';

export const SIVA_CONTRACT_PHASE_KEY = 'siva-contract-phase-key';
export const SIVA_CONTRACT_AVAILABLE_PHASES_KEY = 'siva-contract-available-phases-key';
export const SIVA_COMPANY_AVAILABLE_PHASES_KEY = 'siva-company-available-phases-key';

export const SIVA_CONTRACTS_KEY = 'siva-contracts';
export const SIVA_CONTRACTS_DETAIL_KEY = 'siva-contract-details';

export const PORTFOLIO_INDUSTRIES_KEY = 'portfolio-industries';

export const MUNICIPALITY_DETAILS_KEY = 'municipality-details';

export const EXTERNAL_ACCOUNTING_DATA_KEY = 'external-accounting-data';
export const EXTERNAL_COMPANY_KEY = 'external-company';
export const KTU_DATE_KEY = 'ktu-date';
