import { Notification } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClusterMemberDto } from 'src/api/v2';
import LinkButtonDetails from 'src/components/LinkButtonDetails/LinkButtonDetails';
import { formatOrganizationNumber } from 'src/utils/format';

type Props = {
  items: ClusterMemberDto[];
};

const ImportMembersDuplicateMessage: React.FC<Props> = ({ items }) => {
  const { t: tCommon } = useTranslation();
  const { t: tResourceBase } = useTranslation('resourceBase');

  if (items.length === 0) {
    return null;
  }

  return (
    <Notification type="info" fullWidth className="margin-bottom--3">
      <p>{tResourceBase('import.alreadyInResourceBase')}</p>
      <p>
        {tCommon('count')}: {items.length} {tCommon('piece', { count: items.length })}
      </p>

      <LinkButtonDetails text={tCommon('showDetails')}>
        <ul>
          {items.map(({ id, name, organizationNumber }) => (
            <li key={id}>
              {name} (
              {organizationNumber ? formatOrganizationNumber(organizationNumber) : 'Uten organisasjonsnummer'}
              {/* // TODO: Oversett */})
            </li>
          ))}
        </ul>
      </LinkButtonDetails>
    </Notification>
  );
};

export default ImportMembersDuplicateMessage;
