import { FaIcon, FaIcons } from '@in/component-library';
import classNames from 'classnames';
import React from 'react';
import TransparentButton from 'src/components/TransparentButton/TransparentButton';
import { useSivaBudgetDetailsList } from '../../hooks/use-siva-budget-details-list';

type Props = {
  budgetId: string;
};

const BudgetDownloadButton: React.FC<Props> = ({ budgetId }) => {
  const { mutations } = useSivaBudgetDetailsList(undefined);

  return (
    <TransparentButton
      aria-label="Last ned budsjett"
      onClick={async () => await mutations.download.mutateAsync(budgetId)}
      disabled={mutations.download.isLoading}
      className={classNames({
        'animation--spin': mutations.download.isLoading,
      })}
    >
      <FaIcon name={mutations.download.isLoading ? FaIcons.SpinnerLight : FaIcons.ArrowToBottomLight} />
    </TransparentButton>
  );
};

export default BudgetDownloadButton;
