import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';

import { Button, ErrorMessage, Theme } from '@in/component-library';

import { ClusterContactCreateDto, ClusterContactStatus } from 'src/api/v2';

import { toastPromise } from 'src/utils/toast';

import useCluster from 'src/features/cluster/hooks/use-cluster';
import { useContacts } from 'src/features/contacts';
import useEffectOnce from 'src/hooks/use-effect-once';

import ContactAccordion from '../../common/ContactAccordion';
import hotjarEvent from 'src/utils/hotjarEvent';

type Props = {
  onClose?: () => void;
};

type FormData = {
  contacts: ClusterContactCreateDto[];
};

const CreateContactForm: React.FC<Props> = ({ onClose }) => {
  const { t: tCommon } = useTranslation();
  const { t: tResourceBase } = useTranslation('resourceBase');

  const { cluster } = useCluster();
  const { createListMutation } = useContacts();

  const [activeAccordion, setActiveAccordion] = React.useState<number>(0);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm<FormData>({
    shouldUnregister: true,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  });

  const watchFields = watch('contacts');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFields[index],
    };
  });

  const onSubmit = handleSubmit((data) => {
    if (data.contacts.length === 0) {
      setError('Ingen kontakter å legge til. Vennligst legg til en kontakt før du lagrer.'); // TODO: Translate
      return;
    }

    setIsSubmitting(true);
    let clusterContacts: ClusterContactCreateDto[] = [...data.contacts];

    if (cluster) {
      clusterContacts = clusterContacts.map((clusterContact): ClusterContactCreateDto => {
        const record: ClusterContactCreateDto = {
          ...clusterContact,
          clusterId: cluster.id,
          status: ClusterContactStatus.Active,
          interestAreaIds: [],
          isClusterMemberMainContact: false,
          clusterMemberId: clusterContact.clusterMemberId === '' ? undefined : clusterContact.clusterMemberId,
        };

        return record;
      });
    }

    const promise = createListMutation.mutateAsync(clusterContacts);

    toastPromise(promise, {
      error: 'Kunne ikke opprette kontakt. Vennligst prøv igjen.', // TODO: Translate
    })
      .then(() => {
        onClose?.();
        hotjarEvent('contact created');
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  });

  useEffectOnce(() => {
    if (fields.length === 0) {
      // @ts-ignore
      //TODO: tomt objekt stemmer ikke overens med forventet model, gir feil
      append({});
    }
  });

  return (
    <div>
      <form onSubmit={onSubmit}>
        {controlledFields.map((field, index) => {
          let title: React.ReactNode = 'Ny kontakt'; // TODO: Translate

          const firstName = field.firstName;
          const lastName = field.lastName;

          if (firstName || lastName) {
            title = `${firstName || ''} ${lastName || ''}`;
          }

          if (errors.contacts && errors.contacts[index]) {
            const hasErrors =
              errors.contacts[index]?.firstName ||
              errors.contacts[index]?.lastName ||
              errors.contacts[index]?.emailAddress;

            if (hasErrors) {
              title = <span style={{ color: '#ed0000' }}>{title}</span>;
            }
          }

          return (
            <ContactAccordion
              key={field.id}
              title={title}
              open={index === activeAccordion && !isSubmitting}
              index={index}
              register={register}
              fields={fields}
              field={field}
              errors={errors}
              remove={remove}
              isSubmitting={isSubmitting}
              clusterId={cluster!.id}
              selectMember={true}
              setValue={setValue}
            />
          );
        })}

        {error && (
          <div>
            <ErrorMessage errorMessage={error} />
          </div>
        )}

        <div className="display--flex justify-content--space-between align-items--center margin-top--2">
          <div>
            <Button
              variant="link"
              onClick={() => {
                if (!isSubmitting) {
                  // @ts-ignore
                  //TODO: tomt objekt stemmer ikke overens med forventet model, gir feil
                  append({});
                  setActiveAccordion(fields.length);
                  if (error) {
                    setError('');
                  }
                }
              }}
            >
              + {tResourceBase('addContact')}
            </Button>
          </div>

          <Button theme={Theme.Positive} type="submit" isLoading={isSubmitting}>
            {tCommon('save')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreateContactForm;
