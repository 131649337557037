import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useBoolean } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';

import { Theme, ButtonVariant, FaIcons } from '@in/component-library';

import { TimeEntryDto, TimeEntryStatus } from 'src/api/v2';

import useMembers from 'src/hooks/use-members';
import { useContacts } from 'src/features/contacts';
import useTimeEntries from 'src/hooks/use-time-entries';

import { toastPromise } from 'src/utils/toast';

import EditInkindModal from './EditInkindModal';
import DeleteInkindModal from './DeleteInkindModal';
import RegisterInkindModal from './RegisterInkindModal';

import { DataTableV3Ids } from 'src/constants/dataTableIds';
import DataTableV3, { DataTableV3Actions } from 'src/components/DataTableV3';
import { useInkindColumns } from '../hooks/use-inkind-column';

type Props = {
  writeAccess?: boolean;
  operationId?: string | null | undefined;
};

const InkindTable: React.FC<Props> = ({ writeAccess, operationId }) => {
  const { t: tCommon } = useTranslation();
  const { t: tError } = useTranslation('error');
  const { t: tButtons } = useTranslation('buttons');
  const { t: tSelfEffort } = useTranslation('selfEffort');

  const { combined } = useMembers();
  const { contacts } = useContacts();
  const { timeEntries, operationTimeEntriesById, createTimeEntryListMutation } = useTimeEntries({
    operationId: operationId,
    enabled: true,
    clusterMemberCategoryId: null,
  });

  const data = useMemo(() => {
    if (operationId) {
      return operationTimeEntriesById;
    }

    return timeEntries;
  }, [operationId, operationTimeEntriesById, timeEntries]);

  const createModal = useBoolean();
  const editModal = useBoolean();
  const deleteModal = useBoolean();

  const [selectedRows, setSelectedRows] = useState<TimeEntryDto[]>([]);

  const actions = useMemo<DataTableV3Actions<TimeEntryDto>>(() => {
    const buttons: DataTableV3Actions<TimeEntryDto> = {
      primary: writeAccess
        ? [
            {
              label: tButtons('edit'),
              variant: ButtonVariant.Outlined,
              onClick: (rows) => {
                if (rows.length === 0) {
                  toast.info(tError('least1RowForAction'));
                  return;
                }

                setSelectedRows(rows);
                editModal.setTrue();
              },
            },
            {
              label: tCommon('delete'),
              theme: Theme.Danger,
              variant: ButtonVariant.Outlined,
              onClick: (rows) => {
                if (rows.length === 0) {
                  toast.info(tError('least1RowForAction'));
                  return;
                }

                setSelectedRows(rows);
                deleteModal.setTrue();
              },
            },
            {
              label: tCommon('approve'),
              theme: Theme.Positive,
              icon: FaIcons.PlusLight,
              onClick: async (rows) => {
                if (rows.length === 0) {
                  toast.info(tError('least1RowForAction'));
                  return;
                }

                const toApproval = rows.filter((row) => row.status !== TimeEntryStatus.Counting);

                if (toApproval.length === 0) {
                  toast.info(tSelfEffort('markedRowsAlreadyApproved'));
                  return true;
                }

                const promise = createTimeEntryListMutation.mutateAsync(
                  toApproval.map((row) => ({ ...row, status: TimeEntryStatus.Counting })),
                );

                await toastPromise(promise, {
                  error: tError('selfEffortNotApproved'),
                });

                // clear rows
                return true;
              },
            },
            {
              label: tButtons('registerSelfEffort'),
              icon: FaIcons.PlusLight,
              onClick: () => createModal.setTrue(),
            },
          ]
        : [],
    };

    return buttons;
  }, [
    createModal,
    createTimeEntryListMutation,
    deleteModal,
    editModal,
    tButtons,
    tCommon,
    tError,
    tSelfEffort,
    writeAccess,
  ]);

  const columns = useInkindColumns({ contacts, members: combined });

  return (
    <>
      <h1>{tSelfEffort('inkindOverview')}</h1>

      <DataTableV3
        id={DataTableV3Ids.ResourceBase.Inkind}
        data={data}
        columns={columns}
        actions={actions}
        hideFilterText
      />

      <EditInkindModal
        show={editModal.value}
        onClose={() => {
          editModal.setFalse();
          setSelectedRows([]);
        }}
        selectedTimeEntries={selectedRows}
      />

      <DeleteInkindModal
        show={deleteModal.value}
        onClose={() => {
          deleteModal.setFalse();
          setSelectedRows([]);
        }}
        selectedTimeEntries={selectedRows}
      />

      <RegisterInkindModal
        show={createModal.value}
        showAllProjects
        onClose={() => {
          createModal.setFalse();
          setSelectedRows([]);
        }}
      />
    </>
  );
};

export default InkindTable;
