import { Button, ButtonVariant, FaIcons } from '@in/component-library';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import React from 'react';
import { DataTableExcelExportButtonProps } from '../types';
import { useTranslation } from 'react-i18next';

const DataTableExcelExportButton = <TData extends object>({
  table,
}: DataTableExcelExportButtonProps<TData>) => {
  const { t: tTableTitles } = useTranslation('tableTitles');

  const onClickExport = () => {
    const isSomeRowsSelected = table.getIsSomeRowsSelected();

    const rows = table
      .getSortedRowModel()
      .flatRows.map((row) => row)
      .filter((row) => {
        if (isSomeRowsSelected) {
          return row.getIsSelected();
        }

        return true;
      });

    const columns = table
      .getVisibleLeafColumns()
      .map((column) => column)
      // Remove columns that start with __ (internal columns)
      .filter(({ id }) => !id.startsWith('__'));

    const data = rows.map((row) =>
      columns.map(({ id }) => {
        const value = row.getValue(id);

        switch (value) {
          case 'undefined':
          case 'null':
          case '':
          case undefined:
          case null:
            return null;

          default:
            return value;
        }
      }),
    );

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Data');

    worksheet.columns = columns.map((column, index) => ({
      header: tTableTitles(column.id as any),
      key: column.id,

      width:
        data.reduce((acc, curr) => {
          const value = curr[index];

          if (value === null) {
            return acc;
          }

          const valueLength = (value as any)?.toString().length || 0;

          return valueLength > acc ? valueLength : acc;
        }, column.id.toString().length) * 1.1,
    }));

    worksheet.getRow(1).font = {
      bold: true,
    };

    worksheet.addRows(data);

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      });

      saveAs(blob, `${'export'}-${new Date().toJSON()}.xlsx`);
    });
  };

  return (
    <Button
      type="button"
      variant={ButtonVariant.Link}
      iconName={FaIcons.FileExcelLight}
      onClick={onClickExport}
    >
      {tTableTitles('export')}
    </Button>
  );
};

export default DataTableExcelExportButton;
