import React, { ReactNode } from 'react';
import css from './Page.module.scss';
import { Section } from '@in/component-library';
type PageBodyProps = {
  noPadding?: boolean;
  children: ReactNode;
};
const PageBody = ({ noPadding, children }: PageBodyProps) => {
  return (
    <Section className={`${css['page']}${noPadding ? ' padding-top--0 padding-bottom--0' : ''}`}>
      {children}
    </Section>
  );
};
export default PageBody;
