import React from 'react';
import './TransparentButton.scss';

//EsLint workaround. className not defined in props.
type ITransparentButton = React.ButtonHTMLAttributes<HTMLButtonElement>;

const TransparentButton: React.FC<ITransparentButton> = ({ children, className, ...props }) => {
  const classNames = className ? `transparent-button ${className}` : 'transparent-button';
  return (
    <button {...props} className={classNames}>
      {children}
    </button>
  );
};

export default TransparentButton;
