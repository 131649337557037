import { ClusterStatusDto } from 'src/api/v2';

// Denne er tatt i fra SLv2
export const StaticIdentifiers = {
  ClusterStatuses: {
    ClusterInitiative: '49527767-f08c-4dc9-b537-abe333fda58c',
    Arena: 'c53b2d53-def2-4cf3-a29e-7816f09a71e9',
    ArenaPro: '00732128-4a69-4489-82f7-c35be1ffb466',
    Nce: 'd4defd0c-66f3-48e2-ae6e-5fe98d93f2ba',
    NceOutsideNic: '3ee05649-e9c4-4f06-a3b5-1a4d00da3fda',
    Gce: '50d613ca-28bc-4664-bc38-fda1187911ac',
    MatureClusters: 'e879bb8a-a682-491d-a3cd-b3d4a8d14032',
    BusinessNetwork: '1441db79-d147-4181-b1aa-9b2719ddb839',
    Network: '3fc85695-240f-41d1-a7d4-cf0d3b7d2877',
    BusinessGarden: '26addf9d-4d40-47e2-b204-0f3f46a92dba',
    SivaAdminDev: 'bc43eabe-1249-4f8f-63fe-08dbe45624a9',
    SivaAdminTest: 'b8ad97b3-aa74-4d61-f9d1-08dbf250371e',
    SivaAdminProd: 'bb407aed-be3b-412a-2b55-08dbe68e9367',
    SivaBusinessGarden: 'bcabb9fb-a716-49c1-0f03-08dbb82f9879',
    SivaIncubator: '0e9ae81b-7499-49f7-0f04-08dbb82f9879',
    NotSet: 'c4a42dcf-ce43-4431-9b73-4d624a9fe89f',
  },
} as const;

export type ClusterStatusCodes = keyof typeof StaticIdentifiers.ClusterStatuses;

// Lagrer key og id i array her for å kunne gjøre en Array.find() på id fra backend
export const ClusterStatusesArray = Object.keys(StaticIdentifiers.ClusterStatuses).map((key) => ({
  key: key as ClusterStatusCodes,
  id: StaticIdentifiers.ClusterStatuses[key] as string,
}));

// Lagrer all data her, samme som SLv2 sin data
export const ClusterStatuses: Record<ClusterStatusCodes, ClusterStatusDto> = {
  Arena: {
    id: StaticIdentifiers.ClusterStatuses.Arena,
    name: 'Arena',
    code: 'ARENA',
    description: 'Arena',
    operationsApplicationFrame: 2000000,
    developmentApplicationFrame: 0,
    totalApplicationFrame: 0,
    modules: [],
    statusProgramIds: [],
  },
  ClusterInitiative: {
    id: StaticIdentifiers.ClusterStatuses.ClusterInitiative,
    name: 'Cluster Initiative',
    code: 'CLUSTER_INITIATIVE',
    description: 'Cluster Initiative',
    operationsApplicationFrame: 0,
    developmentApplicationFrame: 0,
    totalApplicationFrame: 0,
    modules: [],
    statusProgramIds: [],
  },
  ArenaPro: {
    id: StaticIdentifiers.ClusterStatuses.ArenaPro,
    name: 'Arena Pro',
    code: 'ARENA_PRO',
    description: 'Arena Pro',
    operationsApplicationFrame: 3000000,
    developmentApplicationFrame: 3000000,
    totalApplicationFrame: 0,
    modules: [],
    statusProgramIds: [],
  },
  Nce: {
    id: StaticIdentifiers.ClusterStatuses.Nce,
    name: 'NCE',
    code: 'NCE',
    description: 'NCE',
    developmentApplicationFrame: 0,
    operationsApplicationFrame: 0,
    totalApplicationFrame: 5000000,
    modules: [],
    statusProgramIds: [],
  },
  NceOutsideNic: {
    id: StaticIdentifiers.ClusterStatuses.NceOutsideNic,
    name: 'NCE - outside NIC',
    code: 'NCE_OUTSIDE_NIC',
    description: 'NCE - outside NIC',
    developmentApplicationFrame: 0,
    operationsApplicationFrame: 0,
    totalApplicationFrame: 0,
    modules: [],
    statusProgramIds: [],
  },
  Gce: {
    id: StaticIdentifiers.ClusterStatuses.Gce,
    name: 'GCE',
    code: 'GCE',
    description: 'GCE',
    totalApplicationFrame: 10000000,
    developmentApplicationFrame: 0,
    operationsApplicationFrame: 0,
    modules: [],
    statusProgramIds: [],
  },
  MatureClusters: {
    id: StaticIdentifiers.ClusterStatuses.MatureClusters,
    name: 'Modne Klynger',
    code: 'MODNE_KLYNGER',
    description: 'Modne klynger',
    totalApplicationFrame: 8000000,
    developmentApplicationFrame: 0,
    operationsApplicationFrame: 0,
    modules: [],
    statusProgramIds: [],
  },
  BusinessNetwork: {
    id: StaticIdentifiers.ClusterStatuses.BusinessNetwork,
    name: 'Business Network',
    code: 'BUSINESS_NETWORK',
    description: 'Business Network',
    totalApplicationFrame: 0,
    developmentApplicationFrame: 0,
    operationsApplicationFrame: 0,
    modules: [],
    statusProgramIds: [],
  },
  Network: {
    id: StaticIdentifiers.ClusterStatuses.Network,
    name: 'Network',
    code: 'NETWORK',
    description: 'Network',
    developmentApplicationFrame: 0,
    operationsApplicationFrame: 0,
    totalApplicationFrame: 0,
    modules: [],
    statusProgramIds: [],
  },
  BusinessGarden: {
    id: StaticIdentifiers.ClusterStatuses.BusinessGarden,
    name: 'Business Garden',
    code: 'BUSINESS_GARDEN',
    description: 'Business Garden',
    developmentApplicationFrame: 0,
    operationsApplicationFrame: 0,
    totalApplicationFrame: 0,
    modules: [],
    statusProgramIds: [],
  },
  SivaAdminDev: {
    id: StaticIdentifiers.ClusterStatuses.SivaAdminDev,
    name: 'SIVA Admin Status',
    code: 'SIVA_ADMIN_STATUS',
    description: '',
    operationsApplicationFrame: 0,
    developmentApplicationFrame: 0,
    totalApplicationFrame: 0,
    modules: [],
    statusProgramIds: [],
  },
  SivaAdminTest: {
    id: StaticIdentifiers.ClusterStatuses.SivaAdminTest,
    name: 'SIVA - Programadmin',
    code: 'SIVA_PROGRAMADMIN',
    description: 'Programstatus for SIVAs administratoraktør.',
    operationsApplicationFrame: 0.0,
    developmentApplicationFrame: 0.0,
    totalApplicationFrame: 0.0,
    modules: [],
    statusProgramIds: [],
  },
  SivaAdminProd: {
    id: StaticIdentifiers.ClusterStatuses.SivaAdminProd,
    name: 'SIVA - Programadmin',
    code: 'SIVA_PROGRAMADMIN',
    description: 'Programstatus for SIVAs administratoraktør.',
    operationsApplicationFrame: 0.0,
    developmentApplicationFrame: 0.0,
    totalApplicationFrame: 0.0,
    modules: [],
    statusProgramIds: [],
  },
  SivaBusinessGarden: {
    id: StaticIdentifiers.ClusterStatuses.SivaBusinessGarden,
    name: 'Næringshage',
    code: 'BUSINESS_GARDEN',
    description: 'Næringshage i SIVA-programmet',
    operationsApplicationFrame: 0,
    developmentApplicationFrame: 0,
    totalApplicationFrame: 0,
    modules: [],
    statusProgramIds: [],
  },
  SivaIncubator: {
    id: StaticIdentifiers.ClusterStatuses.SivaIncubator,
    name: 'Inkubator',
    code: 'INCUBATOR',
    description: 'Inkubator i SIVA-programmet',
    operationsApplicationFrame: 0,
    developmentApplicationFrame: 0,
    totalApplicationFrame: 0,
    modules: [],
    statusProgramIds: [],
  },
  NotSet: {
    id: StaticIdentifiers.ClusterStatuses.NotSet,
    name: 'Not Set',
    code: 'NOT_SET',
    description: 'Default status for Clusters on Creation/Synchronization',
    developmentApplicationFrame: 0,
    operationsApplicationFrame: 0,
    totalApplicationFrame: 0,
    modules: [],
    statusProgramIds: [],
  },
};
