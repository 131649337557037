import { useMemo } from 'react';
import { t } from 'i18next';

import { sortOptionsByValue } from '../helpers/sortOptions';

import { type CheckboxOptionProps } from '@in/component-library';

export type StatusFilterType = 'new' | 'recieved' | 'wip' | 'archived';

const useStatusTags = (selectedStatusIds: string[]) => {
  const statusOptions: CheckboxOptionProps[] = useMemo(() => {
    const selectedStatuses = selectedStatusIds.map((id) => id as StatusFilterType);
    return [
      {
        value: 'new',
        text: t('oneWayIn:status.New'),
        selected: selectedStatuses.includes('new') || selectedStatuses.includes('recieved'),
      },
      {
        value: 'wip',
        text: t('oneWayIn:status.wip'),
        selected: selectedStatuses.includes('wip'),
      },
      {
        value: 'archived',
        text: t('oneWayIn:status.Archived'),
        selected: selectedStatuses.includes('archived'),
      },
    ] as CheckboxOptionProps[];
  }, [selectedStatusIds]);

  return { statusOptions: sortOptionsByValue(statusOptions) };
};

export default useStatusTags;
