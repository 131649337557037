import { Notification } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LinkButtonDetails from 'src/components/LinkButtonDetails/LinkButtonDetails';
import { formatOrganizationNumber } from 'src/utils/format';

export type InvalidMember = {
  name: string;
  organizationNumber: string;
  rowNumber: number;
  reason: string;
};

type Props = {
  items: InvalidMember[];
};

const ImportMembersInvalidMessage: React.FC<Props> = ({ items }) => {
  const { t: tCommon } = useTranslation();
  const { t: tResourceBase } = useTranslation('resourceBase');

  if (items.length === 0) {
    return null;
  }

  return (
    <Notification type="error" fullWidth className="margin-bottom--3">
      <p>{tResourceBase('import.invalidOrganizationNumber.description')}</p>

      <LinkButtonDetails text={tCommon('showDetails')}>
        <ul>
          {items.map(({ name, organizationNumber, rowNumber, reason }, index) => (
            <li key={index}>
              {name} ({formatOrganizationNumber(organizationNumber)}) - <em>{reason}</em> ({tCommon('row')}:{' '}
              {rowNumber})
            </li>
          ))}
        </ul>
      </LinkButtonDetails>
    </Notification>
  );
};

export default ImportMembersInvalidMessage;
