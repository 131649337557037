import { MessageType, Notification, PageLoader } from '@in/component-library';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CustomerInfoDto } from 'src/api/v2';
import Table from 'src/components/Table';
import { useExternalAccountingData } from 'src/hooks/use-external-accounting-data';
import { formatOrganizationNumber } from 'src/utils/format';
import CompanyEnterpriseStatusTag from './CompanyEnterpriseStatusTag';

type Props = {
  company: CustomerInfoDto;
  id: string;
  isActive: boolean;
};

const CompanyInformationEconomyTab: React.FC<Props> = ({ company, id, isActive }) => {
  const { t: tSiva } = useTranslation('siva');

  const { data, isLoading } = useExternalAccountingData(company.publicId);

  const item = useMemo(() => {
    if (data) {
      const latestYear = Math.max(...data.map((item) => item.fiscalYear));
      const item = data.find((item) => item.fiscalYear === latestYear);

      return item;
    }

    return undefined;
  }, [data]);

  function formatNOK(value: number) {
    // values are in thousands
    const result = value * 1000;

    return new Intl.NumberFormat('nb-NO', {
      style: 'currency',
      currency: 'NOK',
      minimumFractionDigits: 0,
    })
      .format(result)
      .replace('kr', 'NOK');
  }

  const tableData = useMemo(() => {
    const data = {
      salesIncome: 0, // omsetning
      balance: 0, // balanse
      valueCreation: 0, // verdiskapning
      operationalResults: 0, // driftsresultat
      salaryExpenses: 0, // lonnskostnader
    };

    if (item) {
      data.salesIncome = item.salesIncome || 0;
      data.operationalResults = item.operationalResults || 0;
      data.salaryExpenses = item.salaryExpenses || 0;
      data.valueCreation = data.operationalResults + data.salaryExpenses;

      data.balance = item.debtAndEquity || 0; // TODO: Finne ut hvordan vi regner ut denne
    }

    return [
      {
        key: 'salesIncome',
        label: tSiva('companyTabs.economy.salesIncome'),
        value: formatNOK(data.salesIncome),
      },
      {
        key: 'balance',
        label: tSiva('companyTabs.economy.balance'),
        value: formatNOK(data.balance),
      },
      {
        key: 'valueCreation',
        label: tSiva('companyTabs.economy.valueCreation'),
        value: formatNOK(data.valueCreation),
      },
      {
        key: 'operationalResults',
        label: tSiva('companyTabs.economy.operationalResults'),
        value: formatNOK(data.operationalResults),
        className: 'padding-left--2',
      },
      {
        key: 'salaryExpenses',
        label: tSiva('companyTabs.economy.salaryExpenses'),
        value: formatNOK(data.salaryExpenses),
        className: 'padding-left--2',
      },
      {
        key: 'enterpriseStatus',
        label: tSiva('companyTabs.economy.enterpriseStatus'),
        value: <CompanyEnterpriseStatusTag orgNo={company.publicId} />,
      },
    ] satisfies { key: string; label: React.ReactNode; value: React.ReactNode; className?: string }[];
  }, [company.publicId, item, tSiva]);

  if (isLoading) {
    return (
      <div id={id} role="tabpanel" tabIndex={0} hidden={!isActive}>
        <h3>{tSiva('companyTabs.economy.loading.text')}</h3>
        <PageLoader aria-label={tSiva('companyTabs.economy.loading.text')} />
      </div>
    );
  }

  return (
    <div id={id} role="tabpanel" tabIndex={0} hidden={!isActive}>
      {item ? (
        <h3>
          {tSiva('companyTabs.about.title', {
            value: item.fiscalYear,
          })}
        </h3>
      ) : (
        <Notification type={MessageType.Info} fullWidth className="margin-bottom--4">
          <p>
            <Trans
              t={tSiva}
              i18nKey={'companyTabs.economy.notification.text1'}
              values={{ name: company.name, orgNo: formatOrganizationNumber(company.publicId) }}
              components={{
                '1': <strong>{company.name}</strong>,
              }}
            >
              Kunne ikke finne regnskaps data for <strong>{company.name}</strong> (
              {formatOrganizationNumber(company.publicId)}).
            </Trans>
          </p>

          <p>{tSiva('companyTabs.economy.notification.text2')}</p>
        </Notification>
      )}

      <Table>
        <Table.Body>
          {tableData.map((item) => (
            <Table.Row key={item.key}>
              <Table.Cell className="width--256px max-width--256px">
                <strong className={item?.className}>{item.label}</strong>
              </Table.Cell>
              <Table.Cell>{item.value}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default CompanyInformationEconomyTab;
