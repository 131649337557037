import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ONE_WAY_IN_FEEDBACK, ONE_WAY_IN_FEEDBACK_LIST } from 'src/constants/hook-keys';
import { useCluster } from 'src/features/cluster';
import { OneWayInFeedbackService, OneWayInFeedbackUpsertDto } from 'src/api/v2';
import { useAuth } from 'src/features/authorization';
import { useTranslation } from 'react-i18next';

export default function useOneWayInFeedback(formId?: string) {
  const { t: tCommon } = useTranslation('common');
  const { cluster } = useCluster();

  const { checkAccess } = useAuth();
  const { read, write } = checkAccess('OneWayIn');

  const queryClient = useQueryClient();

  const [service] = useState(new OneWayInFeedbackService());

  const { data, ...rest } = useQuery(
    [ONE_WAY_IN_FEEDBACK, formId],
    () => service.getAllFeedbacksForOpportunity(formId!),
    {
      enabled: !!cluster && !!formId && read,
      retry: false,
    },
  );

  const upsertMutation = useMutation(
    (record: OneWayInFeedbackUpsertDto) => {
      if (!write) {
        throw new Error(tCommon('cannotWrite'));
      }

      return service.upsertFeedback(record);
    },
    {
      onSuccess() {
        queryClient.resetQueries([ONE_WAY_IN_FEEDBACK]);
        queryClient.invalidateQueries([ONE_WAY_IN_FEEDBACK_LIST]);
      },
    },
  );

  return {
    opportunityFeedbackCollection: data,
    upsertMutation,
    ...rest,
  };
}
