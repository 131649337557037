import React, { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Icons, IconSize } from '@in/component-library';

import useOneWayIn from '../../../hooks/use-one-way-in';

import ReferringModalForm from './ReferringModalForm';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';

import styles from '../../styles/ReferringModal.module.scss';
import { PageParams } from '../../types/PageParams';
import { TRACKING_ROUTES } from 'src/features/tracking/routes';
import { TRACKING_BASE_ROUTE } from 'src/navigation';
import siteimproveEvent from 'src/utils/siteimprove';
import { SITEIMPROVE_DECLARATIONS } from 'src/features/tracking/constants';

type ReferringModalProps = {
  actors: { id: string; name: string | undefined }[];
  onAddDisableModal: () => void;
};

const ReferringModal: React.FC<ReferringModalProps> = ({ actors, onAddDisableModal }) => {
  const { t: tCommon } = useTranslation();
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const [comment, setComment] = useState('');
  const [isVisibleTextAreaMessage, setIsVisibleTextAreaMessage] = useState(false);
  const navigate = useNavigate();
  const [_, setSearchParams] = useSearchParams();

  const { formId } = useParams<PageParams>();

  const { oneWayInOpportunityFormQuery, shareOneWayInOpportunity } = useOneWayIn(formId);

  if (actors.length === 0) {
    return <div>{tOneWayIn('details.Referral.NoneChosen')}</div>;
  }

  const { isError, isSuccess, isIdle, isLoading } = shareOneWayInOpportunity;

  const handleSendActorMessage = () => {
    if (comment === '') {
      return setIsVisibleTextAreaMessage(true);
    }

    setIsVisibleTextAreaMessage(false);

    shareOneWayInOpportunity
      .mutateAsync({
        createDto: {
          receivingClusterIds: actors.map((row) => row.id),
          description: comment,
        },
        opportunityId: oneWayInOpportunityFormQuery.data?.opportunity?.id || '',
      })
      .then(() => setSearchParams('referralStatus=success', { replace: true }))
      .catch(() => setSearchParams('referralStatus=failure', { replace: true }));
  };

  if (oneWayInOpportunityFormQuery.isLoading) {
    return (
      <div className="width--50">
        <LoadingPage />
      </div>
    );
  }

  return (
    <>
      <p>{tOneWayIn('details.Referral.ReferToSelectedProviders')}</p>
      <ul>
        {actors.map((row) => {
          return <li key={row.id}>{row.name}</li>;
        })}
      </ul>

      {isIdle && (
        <ReferringModalForm
          onAddDisableModal={() => {
            siteimproveEvent(
              SITEIMPROVE_DECLARATIONS.CATEGORIES.FORWARDOPPORTUNITY,
              SITEIMPROVE_DECLARATIONS.ACTIONS.ABORT_BUTTON,
              formId,
            );
            onAddDisableModal();
          }}
          onAddCommentField={(e) => setComment(e.target.value)}
          isVisibleTextAreaMessage={isVisibleTextAreaMessage}
          handleSubmit={handleSendActorMessage}
        />
      )}

      {isLoading && (
        <div className={styles['loading-spinner']}>
          <Icon name={Icons.Spinner} size={IconSize.Small} />
        </div>
      )}

      {isSuccess && (
        <>
          <p>{tOneWayIn('details.Referral.Actor.QueryData.isSuccess')}</p>
          <Button
            onClick={() => {
              // navigate user to /track/opportunity-forward in order to track User Journey
              siteimproveEvent(
                SITEIMPROVE_DECLARATIONS.CATEGORIES.FORWARDOPPORTUNITY,
                SITEIMPROVE_DECLARATIONS.ACTIONS.SEND_SUCCESS,
                formId,
              );
              navigate(TRACKING_BASE_ROUTE + TRACKING_ROUTES.FORWARD_OPPORTUNITY);
            }}
          >
            {tCommon('OK')}
          </Button>
        </>
      )}

      {isError && (
        <>
          <p>{tOneWayIn('details.Referral.Actor.QueryData.isError')}</p>
          <Button
            onClick={() => {
              siteimproveEvent(
                SITEIMPROVE_DECLARATIONS.CATEGORIES.FORWARDOPPORTUNITY,
                SITEIMPROVE_DECLARATIONS.ACTIONS.SEND_FAILURE,
                formId,
              );
              navigate(TRACKING_BASE_ROUTE + TRACKING_ROUTES.FORWARD_OPPORTUNITY);
            }}
          >
            {tCommon('OK')}
          </Button>
        </>
      )}
    </>
  );
};

export default ReferringModal;
