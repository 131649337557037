export const SITEIMPROVE_DECLARATIONS = {
  CATEGORIES: {
    FORWARDOPPORTUNITY: 'ForwardOpportunity',
  },
  ACTIONS: {
    SEND_SUCCESS: 'SuccessfullySent',
    SEND_FAILURE: 'FailedToSend',
    ABORT_BUTTON: 'AbortedByButton',
    ABORT_X: 'AbortedAsX',
  },
};
