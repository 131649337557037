import React, { ReactNode } from 'react';

type Props = {
  icon: ReactNode;
  iconName: string;
  description: string;
};

const ActionPageDescription: React.FC<Props> = ({ icon, iconName, description }) => {
  return (
    <>
      <div className="display--flex flex-direction--column justify-content--center text-align--center margin-top--3 margin-bottom--3 gap--1">
        {icon}
        <span>{iconName}</span>
      </div>
      <div>{description}</div>
    </>
  );
};

export default ActionPageDescription;
