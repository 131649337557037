import React, { useEffect, useState } from 'react';
import DialogContainer, { DialogContainerProps } from 'src/components/DialogContainer/DialogContainer';
import useOpportunityFormLink from '../../hooks/use-opportunity-form-link';
import { SharedOpportunityFormLinkCreateDto } from 'src/api/v2';
import { useCluster } from 'src/features/cluster';
import { useForm } from 'react-hook-form';
import Input from 'src/components/Form/Input';
import { Button, DateInput, FaIcons, FaIcon } from '@in/component-library';
import { useTranslation } from 'react-i18next';
import css from './CreateLinkToOpportunityForm.module.scss';
import TertiaryButton from 'src/components/TertiaryButton/TertiaryButton';
import siteimproveEvent from 'src/utils/siteimprove';

const MinimumCustomerNameLength = 3;

const CopyToClipboardComponent: React.FC<{ link: string }> = ({ link }) => {
  const [isCopied, setIsCopied] = useState(false);
  const { t: tCommon } = useTranslation('common');
  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => setIsCopied(false), 3000);
      return () => clearTimeout(timer)
    }
  }, [isCopied]);

  return (
    <div className={css['clipboard-component']}>
      <input type="text" readOnly value={link} />
      <button
        type="button"
        aria-label={tCommon("copy")}
        onClick={() => {
          navigator.clipboard.writeText(link);
          setIsCopied(true);
        }}
      >
        <FaIcon name={isCopied ? FaIcons.Check : FaIcons.Copy} />
        {isCopied ? tCommon("copied") : tCommon("copy")}
      </button>
    </div>
  );
};

const CreateLinkToOpportunityForm: React.FC<Omit<DialogContainerProps, 'children'>> = ({
  onClose,
  ...props
}) => {
  const { cluster } = useCluster();
  const { createMutation } = useOpportunityFormLink();
  const [generatedLink, setGeneratedLink] = useState<string | null>(null);
  const [createError, setCreateError] = useState(false);
  const [seeMore, setSeeMore] = useState(false);

  const { t: tCommon } = useTranslation('common');
  const { t: tOneWayIn } = useTranslation('oneWayIn');

  const {
    register,
    formState: { errors },
    watch,
    getValues,
    reset,
  } = useForm<SharedOpportunityFormLinkCreateDto>({
    mode: 'onBlur',
    defaultValues: {
      clusterId: cluster?.id,
    },
  });

  const onCreateOpportunityFormLink = () => {
    setCreateError(false)
    createMutation
      .mutateAsync(getValues())
      .then((link) => {
        setGeneratedLink(link);
      })
      .catch(() => {
        setCreateError(true)
      });
  };

  const watchedCustomerName = watch('customerName');
  return (
    <DialogContainer
      {...props}
      onClose={() => {
        setGeneratedLink(null);
        setCreateError(false)
        reset();
        onClose();
      }}
    >
      <p className='margin-bottom--0'>{tOneWayIn('addOpportunities.main')}</p>
      {!seeMore ? (
        <TertiaryButton minimalPadding onClick={() => setSeeMore(true)} className="mb-20">
          {tCommon('readMore')}
        </TertiaryButton>
      ): (
        <>
          <p>{tOneWayIn('addOpportunities.seeMore.1')}</p>
          <p>
            {tOneWayIn('addOpportunities.seeMore.2')}
            <br />
            {tOneWayIn('addOpportunities.seeMore.3')}
          </p>
          <p style={{ marginBottom: 0 }}>{tOneWayIn('addOpportunities.seeMore.4')}</p>
          <TertiaryButton minimalPadding onClick={() => setSeeMore(false)} className="mb-20">
            {tCommon('readLess')}
          </TertiaryButton>
        </>
      )}

      <Input
        type="text"
        label={tOneWayIn('addOpportunities.identifier')}
        placeholder={tOneWayIn('addOpportunities.placeholder')}
        maxLength={50}
        {...register('customerName', {
          minLength: {
            value: MinimumCustomerNameLength,
            message: tOneWayIn('addOpportunities.identifier.error'),
          },
        })}
        error={errors.customerName?.message}
      />
      <DateInput
        {...register('meetingDate')}
        label={tOneWayIn('addOpportunities.date')}
        errorMsg={errors?.meetingDate?.message}
      />
      {createError ? (
        <p className={css['error-text']}>{tOneWayIn('addOpportunities.error')}</p>
      ) : generatedLink ? (
        <CopyToClipboardComponent link={generatedLink} />)
        : (
        <Button
          isLoading={createMutation.isLoading}
          onClick={() => {
            siteimproveEvent('OPPORTUNITY_ADD', 'Created');
            onCreateOpportunityFormLink();
          }}
          disabled={!watchedCustomerName || watchedCustomerName.length < MinimumCustomerNameLength}
        >
          {tCommon('next')}
        </Button>)}
    </DialogContainer>
  );
};

export default CreateLinkToOpportunityForm;
