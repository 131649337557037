import { Button, ButtonVariant, FaIcon, FaIcons, ProgressBarFull } from '@in/component-library';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingPage } from '../../../constants/HelpAndSupportMenu';
import { useNavigate } from 'react-router-dom';
import siteimproveEvent from 'src/utils/siteimprove';

type Props = {
  pages: OnboardingPage[];
  completeOnboardingPacket: () => void;
  name: string;
  code: string;
  flowCompleted: boolean;
};

const StepConfirmation = ({ pages, completeOnboardingPacket, name, code, flowCompleted }: Props) => {
  const { t: tCommon } = useTranslation();
  const { t: tOnboarding } = useTranslation('onboarding');
  const [step, setStep] = useState(0);
  const [completed, setCompleted] = useState(false);
  const navigate = useNavigate();

  const handleChangeStep = (currentStep: number, action: number) => {
    const moveTo = currentStep + action;
    if (moveTo > pages.length - 1 || moveTo < 0) return;

    siteimproveEvent('Onboarding', code, `${currentStep} - ${moveTo}`);
    setStep(moveTo);
    navigate(pages[moveTo].pageUrl);
  };

  useEffect(() => {
    siteimproveEvent('Onboarding', code, flowCompleted ? 'RepeatStart' : 'Started');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const percent = useMemo(() => {
    return Math.floor(((step + 1) / pages.length) * 100);
  }, [pages.length, step]);

  const onComplete = () => {
    setCompleted(true);
    completeOnboardingPacket();
  };

  return (
    <>
      <p className="font-size--x-large">{name}</p>
      {completed ? (
        <>
          <h2 className="margin-top--1">{tOnboarding('guide.completed.heading')}</h2>
          <p>{tOnboarding('guide.completed.text')}</p>
        </>
      ) : (
        <>
          <h2 className="margin-top--1">
            {tOnboarding('step')} {(step + 1).toString()}. {tOnboarding(pages[step].title)}
          </h2>
          <div className=" margin-bottom--0 display--flex justify-content--space-between">
            <p className="margin-bottom--0">
              {step + 1} {tCommon('of')} {pages.length}
            </p>
            <p className="margin-bottom--0">{percent}%</p>
          </div>
          <ProgressBarFull percentage={percent} />

          <p className="font-size--x-large">{tOnboarding(pages[step].description)}</p>

          <div className="display--flex justify-content--space-between">
            <Button
              disabled={step === 0 ? true : false}
              onClick={() => handleChangeStep(step, -1)}
              className={`${step === 0 ? 'visibility--hidden' : ''}`}
              theme={'neutral'}
              variant={ButtonVariant.Link}
            >
              <FaIcon className={'margin-right--1'} name={FaIcons.ArrowLeft} /> {tCommon('back')}
            </Button>

            {step === pages.length - 1 ? (
              <Button onClick={onComplete}>{tOnboarding('guide.complete.button')}</Button>
            ) : (
              <Button
                onClick={() => handleChangeStep(step, 1)}
                theme={'neutral'}
                variant={ButtonVariant.Link}
              >
                {tCommon('next')} <FaIcon className="margin-left--1" name={FaIcons.ArrowRight} />
              </Button>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default StepConfirmation;
