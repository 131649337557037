import React from 'react';
import { Link } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';

import { truncate } from 'src/utils/string';
import { formatDateToNOString } from 'src/utils/FormatValue';

import { OneWayInListDto } from '../../types/oneWayInList';

import OneWayInOpportunityStatusTag from '../components/OneWayInOpportunityStatusTag';
import { ONE_WAY_IN_DETAILS } from '../../opportunity-details/routes';
import useGeoNorge from '../../hooks/use-geonorge';
import { translateStatusTag } from '../helpers/statusTags';

const columnHelper = createColumnHelper<OneWayInListDto>();

export const useOpportunityTableColumns = () => {
  const { getMunicipalityNameByNumber } = useGeoNorge();

  return [
    columnHelper.accessor('title', {
      id: 'title',
      cell: ({
        getValue,
        row: {
          original: { oneWayInId },
        },
      }) => {
        return (
          <Link aria-label={getValue()} to={ONE_WAY_IN_DETAILS.BASE(oneWayInId)}>
            {truncate(getValue(), 60)}
          </Link>
        );
      },
    }),
    columnHelper.accessor((row) => translateStatusTag(row.status?.status), {
      id: 'status',
      cell: (info) => <OneWayInOpportunityStatusTag status={info.row.original.status?.status} />,
    }),
    columnHelper.accessor((row) => (row.submittedOn ? new Date(row.submittedOn) : null), {
      id: 'submittedDate',
      cell: (info) => (
        <>
          {info.getValue()
            ? formatDateToNOString(info!.getValue()!, false, '{date}.{month}.{year}', true)
            : ''}
        </>
      ),
      minSize: 200,
      sortingFn: 'datetime',
    }),
    columnHelper.accessor('customerName', {
      id: 'customerName',
    }),
    columnHelper.accessor((row) => getMunicipalityNameByNumber(row.municipality), {
      id: 'municipality',
      filterFn: 'includesString',
    }),
  ];
};
