import React, { ReactNode } from 'react';
import './Tooltip.scss';

type Props = {
  direction: 'left' | 'right' | 'top' | 'bottom';
  text: string;
  children: ReactNode;
};

const Tooltip: React.FC<Props> = ({ direction = 'bottom', text, children }) => {
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
  const id = `tooltip_${text.replace(/\s/g, '')}`;

  return (
    <div
      className="tooltip__wrapper"
      aria-describedby={id}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      onTouchStart={() => setShowTooltip(true)}
      onTouchEnd={() => setShowTooltip(false)}
      onFocus={() => setShowTooltip(true)}
      onBlur={() => setShowTooltip(false)}
    >
      {children}
      <div
        id={id}
        hidden={!showTooltip}
        className={`tooltip__text tooltip__text--${direction}`}
        role="tooltip"
      >
        {text}
      </div>
    </div>
  );
};

export default Tooltip;
