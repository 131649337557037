import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

import { Button, ButtonVariant, DateInput, IconButton, Textarea, Theme } from '@in/component-library';

import {
  ClusterContactDto,
  DevelopmentProjectDto,
  DevelopmentProjectListItemDto,
  HttpStatusCode,
  OperationProjectDto,
  PortalProjectDto,
  PortalProjectListItemDto,
  TimeEntrySource,
  TimeEntryStatus,
  TimeEntryUpsertDto,
} from 'src/api/v2';

import Input from 'src/components/Form/Input';
import { Select } from 'src/components/Form/Select';
import Autosuggest from 'src/components/Autosuggest/Autosuggest';

import { useContacts } from 'src/features/contacts';
import useTimeRates from 'src/hooks/use-time-rates';
import { useWorkGroups } from 'src/features/work-groups';
import useTimeEntries from 'src/hooks/use-time-entries';
import useEvents from 'src/hooks/use-events';

import { toastPromise } from 'src/utils/toast';
import { getDateOnlyString } from 'src/utils/date';
import { requiredI18n } from 'src/utils/validation';

import { RegisterInkindDefaultValues } from './RegisterInkindModal';
import { formatDateInputDate } from 'src/utils/FormatValue';
import useProjectSelectOptions from 'src/hooks/use-project-select-options';
import OperationResultList, {
  OperationResultListData,
} from 'src/components/OperationResultList/OperationResultList';
import { convertHourstoHoursAndMinutes } from 'src/utils/time';
import hotjarEvent from 'src/utils/hotjarEvent';
import { SYSTEM_DEFAULT_HOUR_RATE } from '..';

type FormData = TimeEntryUpsertDto & {
  projectId: string;

  timeHours: number;
  timeMinutes: number;
};

type Props = {
  onClose: () => void;

  defaultValues?: RegisterInkindDefaultValues;

  projects:
    | (
        | DevelopmentProjectListItemDto
        | PortalProjectListItemDto
        | DevelopmentProjectDto
        | PortalProjectDto
        | OperationProjectDto
      )[]
    | undefined;
};

const RegisterInkindForm: React.FC<Props> = ({ onClose, defaultValues, projects }) => {
  const { t: tCommon } = useTranslation();
  const { t: tError } = useTranslation('error');
  const { t: tSelfEffort } = useTranslation('selfEffort');

  const form = useForm<FormData>({ defaultValues });

  const projectIdWatch = form.watch('projectId');
  const eventIdWatch = form.watch('eventId');
  const reportedDateWatch = form.watch('reportedDate');

  const { contacts } = useContacts();
  const { workGroups } = useWorkGroups();
  const { createTimeEntryListMutation } = useTimeEntries();
  const { timeRates, defaultTimeRate } = useTimeRates();
  const { events, isLoading: isLoadingEvents } = useEvents(projectIdWatch);

  const { projectSelectOptionsGrouped } = useProjectSelectOptions();

  const [selectedContacts, setSelectedContacts] = useState<
    (ClusterContactDto & { workGroupName?: string; workGroupId?: string })[]
  >([]);
  const [timeEntryErrors, setTimeEntryErrors] = React.useState<OperationResultListData[]>([]);

  const [startDate, endDate] = useMemo(() => {
    const found = events.find((event) => event.id === eventIdWatch);

    if (found) {
      if (found.endDate) {
        return [new Date(found.startDate), new Date(found.endDate)];
      }

      return [new Date(found.startDate), undefined];
    }

    return [undefined, undefined];
  }, [events, eventIdWatch]);

  form.register('reportedDate', {
    validate: {
      minDate: (value) => {
        if (value && startDate) {
          const val = new Date(value);
          const time = new Date(val.getFullYear(), val.getMonth(), val.getDate()).getTime();
          const date = new Date(startDate);
          const min = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();

          if (time < min) {
            return tSelfEffort('cannotBeBeforeDate', { value: getDateOnlyString(startDate) });
          }
        }

        return true;
      },
      maxDate: (value) => {
        if (value && endDate) {
          const val = new Date(value);
          const time = new Date(val.getFullYear(), val.getMonth(), val.getDate()).getTime();
          const date = new Date(endDate);
          const max = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();

          if (time > max) {
            return tSelfEffort('cannotBeAfterDate', { value: getDateOnlyString(endDate) });
          }
        }

        return true;
      },
    },
  });

  const projectOptions = projects
    ? [{ value: '', text: '---' }, ...projects.map((project) => ({ value: project.id, text: project.name }))]
    : projectSelectOptionsGrouped;

  const eventOptions = [
    { value: '', text: '---' },
    ...events.map((event) => ({ value: event.id, text: event.name })),
  ];

  const timeRateOptions = [
    {
      value: '',
      text: tSelfEffort('keepContactHourRate'),
    },
    {
      value: defaultTimeRate?.hourRate || 0,
      text: `${defaultTimeRate?.name || ''} (${(defaultTimeRate?.hourRate || SYSTEM_DEFAULT_HOUR_RATE).toLocaleString()} kr)`,
    },
    ...timeRates.map((timeRate) => ({
      value: timeRate.hourRate,
      text: `${timeRate.name} (${timeRate.hourRate.toLocaleString()} kr)`,
    })),
  ];

  const workGroupOptions = [
    {
      value: '',
      text: '---',
    },
    ...workGroups.map((workGroup) => ({ value: workGroup.id, text: workGroup.name })),
  ];

  const handleOnSubmit = form.handleSubmit(async (formData) => {
    if (selectedContacts.length === 0) {
      toast.error(tSelfEffort('contactNotSelected'));
      return;
    }

    // Tar ut alle verdien som skal videre til record
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { projectId, timeHours, timeMinutes, ...rest } = formData;
    // kopierer verdien i nytt object
    const data = { ...rest };

    const hours = +timeHours + +timeMinutes / 60;
    if (isNaN(hours)) {
      form.setError('timeHours', {
        type: 'custom',
        message: tSelfEffort('hoursIsNaN'),
      });
      return;
    }

    // rund av til 2 desimaler
    data.hours = Math.round(hours * 100) / 100;
    // gjør om til tall
    data.hourRate = +data.hourRate;

    const timeEntries = selectedContacts.map((contact) => {
      const hourRate = data.hourRate === 0 ? contact.timeRate.hourRate : data.hourRate;

      const record: TimeEntryUpsertDto = {
        ...data,
        id: undefined,
        source: TimeEntrySource.Manual,
        status: TimeEntryStatus.Counting,
        hours,
        clusterContactId: contact.id,
        hourRate,
        workGroupId: contact.workGroupId,
      };

      return record;
    });

    setTimeEntryErrors([]);

    const promise = createTimeEntryListMutation.mutateAsync(timeEntries);

    toastPromise(promise, {
      pending: tSelfEffort('submittingInKind'),
      success: tCommon('done'),
      error: tSelfEffort('couldNotCreateInKind'),
    }).then((result) => {
      const failed = result.filter((res) => res.status !== HttpStatusCode.Created);

      if (failed.length) {
        setTimeEntryErrors(
          failed.map((x) => ({
            name: x.data?.clusterContactName ?? '',
            statusCode: x.status,
            message: x.message,
          })),
        );
      } else {
        handleOnClose();
        hotjarEvent('inkind created');
      }
    });
  });

  const handleOnClose = () => {
    form.reset();
    setSelectedContacts([]);
    onClose();
  };

  const handleOnChangeEventId = (value: string) => {
    const found = events.find((event) => event.id === value);
    if (found) {
      const duration = found.duration;

      form.setValue('timeHours', convertHourstoHoursAndMinutes(duration).hours);
      form.setValue('timeMinutes', convertHourstoHoursAndMinutes(duration).minutes);
    }
  };

  return (
    <div>
      <form onSubmit={handleOnSubmit}>
        <Select
          {...form.register('projectId', {
            required: requiredI18n(tError),
          })}
          label={tCommon('project')}
          options={projectOptions}
          error={form.formState.errors.projectId?.message}
          disabled={!!defaultValues?.projectId}
        />
        <Controller
          control={form.control}
          name="eventId"
          rules={{
            required: requiredI18n(tError),
          }}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              label={'Event'}
              options={eventOptions}
              error={fieldState.error?.message}
              disabled={!!defaultValues?.eventId || isLoadingEvents}
              onChange={(e) => {
                field.onChange(e);

                const { value } = e.currentTarget;

                handleOnChangeEventId(value);
              }}
            />
          )}
        />
        <div className="display--flex gap--2">
          <Autosuggest<ClusterContactDto>
            render={(value) => `${value.firstName} ${value.lastName} (${value.emailAddress})`}
            getSuggestionValue={(value) => `${value.firstName} ${value.lastName} (${value.emailAddress})`}
            filter={(value) => {
              const val = value.toLowerCase();

              return contacts
                .filter((x) => !selectedContacts.map((sc) => sc.id).includes(x.id))
                .filter((x) => {
                  const firstName = x.firstName?.toLowerCase() || '';
                  const lastName = x.lastName?.toLowerCase() || '';
                  const fullName = `${firstName} ${lastName}`.trim();
                  const email = x.emailAddress;

                  return fullName.includes(val) || email.includes(val);
                });
            }}
            onValueSelected={(value) => {
              if (!selectedContacts.map((x) => x.id).includes(value.id)) {
                setSelectedContacts((prev) => [...prev, value]);
                return true;
              }
            }}
            label={tCommon('contact')}
            className="flex--3"
          />

          <Controller
            name={'workGroupId'}
            control={form.control}
            render={({ field, fieldState }) => (
              <Select
                {...field}
                label={tSelfEffort('getFromWorkGroup')}
                className="flex--2"
                options={workGroupOptions}
                error={fieldState.error?.message}
                onChange={(e) => {
                  e.preventDefault();
                  const { value } = e.currentTarget;

                  const workGroup = workGroups.find((x) => x.id === value);
                  if (workGroup) {
                    setSelectedContacts((prev) => {
                      const mayContainDuplicates = [
                        ...prev,
                        ...workGroup.clusterContacts.map((contact) => ({
                          ...contact,
                          workGroupName: workGroup.name,
                          workGroupId: workGroup.id,
                        })),
                      ];

                      return mayContainDuplicates.filter(
                        (value, index, self) => index === self.findIndex((t) => t.id === value.id),
                      );
                    });
                  }

                  field.onChange('');
                }}
              />
            )}
          />
        </div>
        <section>
          <div className="display--flex gap--2 align-items--center">
            <IconButton
              className="display--inline-block"
              ariaLabel={tSelfEffort('removeSelectedContact')}
              iconName="crossmark"
              onClick={() => setSelectedContacts([])}
            />

            <strong>{tSelfEffort('removeAll')}</strong>
          </div>
          <hr />
          <div className="display--flex flex-direction--column gap--2 margin-bottom--2">
            {selectedContacts.length === 0 && (
              <div className="margin-top--2 margin-left--2">{tSelfEffort('noContactsSelected')}</div>
            )}
            {selectedContacts.map((contact) => (
              <div key={contact.id} className="display--flex gap--2 align-items--center">
                <IconButton
                  className="display--inline-block"
                  ariaLabel={tSelfEffort('removeSelectedContact')}
                  iconName="crossmark"
                  onClick={() =>
                    setSelectedContacts([...selectedContacts.filter((x) => x.id !== contact.id)])
                  }
                />
                <div>
                  {`${contact.firstName} ${contact.lastName}`}
                  {contact.workGroupName && ` (${contact.workGroupName})`}
                </div>
              </div>
            ))}
          </div>
        </section>
        <div className="display--flex gap--2">
          <Input
            type="number"
            {...form.register('timeHours', {
              validate: {
                subZero: (value) => {
                  if (value < 0) {
                    return tSelfEffort('hoursNotLessThanZero');
                  }

                  return true;
                },
                sumSubZero: () => {
                  const h = +form.getValues('timeHours');
                  const m = +form.getValues('timeMinutes');

                  if (h + m / 60 < 0) {
                    return tSelfEffort('totalTimeLessThanZeroHours');
                  }

                  return true;
                },
              },
            })}
            label={tSelfEffort('numberOfHours')}
            className="flex--1"
            error={form.formState.errors.timeHours?.message}
          />
          <Input
            type="number"
            {...form.register('timeMinutes', {
              validate: {
                subZero: (value) => {
                  if (value < 0) {
                    return tSelfEffort('minutesCannoteBeLessThanZero');
                  }

                  return true;
                },
                sumSubZero: () => {
                  const h = +form.getValues('timeHours');
                  const m = +form.getValues('timeMinutes');

                  if (h + m / 60 < 0) {
                    return tSelfEffort('totalTimeLessThanZeroHours');
                  }

                  return true;
                },
              },
            })}
            label={tSelfEffort('numberOfMinutes')}
            className="flex--1"
            error={form.formState.errors.timeMinutes?.message}
          />
        </div>
        <Select
          {...form.register('hourRate')}
          label={tCommon('timeRate')}
          options={timeRateOptions}
          // error={form.formState.errors.hourRate?.message}
        />

        <DateInput
          name="reportedDate"
          label={tSelfEffort('inkindDateOptional')}
          onChange={(event) => {
            const { value } = event.target;
            const reportedDate = value ? new Date(value) : undefined;
            form.setValue('reportedDate', reportedDate, { shouldValidate: true, shouldDirty: true });
          }}
          value={formatDateInputDate(reportedDateWatch)}
          min={formatDateInputDate(startDate)}
          max={formatDateInputDate(endDate)}
          errorMsg={form.formState.errors.reportedDate?.message}
        />
        <Textarea
          {...form.register('description')}
          label={`${tCommon('description')} (${tCommon('optional')})`}
        />

        <section>
          <OperationResultList data={timeEntryErrors} className="margin-bottom--2" />
        </section>
        <div className="display--flex gap--1">
          <Button type="submit" theme={Theme.Neutral} variant={ButtonVariant.Solid}>
            {tSelfEffort('sendRegistration')}
          </Button>

          <Button
            type="button"
            theme={Theme.Neutral}
            variant={ButtonVariant.Outlined}
            onClick={handleOnClose}
          >
            {tCommon('close')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default RegisterInkindForm;
