import React, { ReactNode, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageParams } from '../types/PageParams';
import { useForm } from 'react-hook-form';
import { SivaContractUpdateDto, SivaPhase } from 'src/api/v2';
import useCompanyContract from '../hooks/use-company-contract';
import { convertIdToNumber } from '../utils/idConversion';
import Input from 'src/components/Form/Input';
import { useTranslation } from 'react-i18next';
import TextArea from 'src/components/Form/TextArea';
import useToggle from 'src/hooks/use-toggle';
import { Button, ButtonVariant, Theme } from '@in/component-library';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import { capitalizeFirstLetter } from 'src/utils/string';
import { formatDateToNOString } from 'src/utils/FormatValue';
import { useCalculateSivaKTUDates } from 'src/hooks/use-siva-ktu-date-calculation';

type Props = {
  onCancel: () => void;
};

const EndContract = ({ onCancel }: Props) => {
  const { t: tCommon } = useTranslation();
  const { t: tSiva } = useTranslation('siva');

  const { contractId } = useParams<PageParams>();

  const { contract, updateSivaContractMutation, currentPhase } = useCompanyContract(
    convertIdToNumber(contractId),
  );

  const { register, handleSubmit, watch } = useForm<SivaContractUpdateDto>({
    defaultValues: {
      ...contract,
      advisorId: contract?.advisorId,
      newPhase: {
        newPhase: SivaPhase.Alumni,
        fromDate: undefined,
      },
    },
  });

  const watchedDate = watch('newPhase.fromDate');

  const [updateError, setUpdateError] = useState(false);
  const { activate, deactivate, isActive } = useToggle();

  const onSave = handleSubmit((formData) => {
    updateSivaContractMutation
      .mutateAsync(formData)
      .then(() => {
        setUpdateError(false);
      })
      .catch(() => {
        setUpdateError(true);
      })
      .finally(() => {
        activate();
      });
  });

  return (
    <>
      <h2>{tSiva('company.details.contract.endContract')}</h2>
      <form onSubmit={onSave}>
        <Input
          {...register('newPhase.fromDate')}
          type="date"
          label={tSiva('company.details.contract.overview.exitDate')}
          className="margin-bottom--3"
          required
        />

        {currentPhase?.sivaPhase && currentPhase.sivaPhase !== SivaPhase.Idea && (
          <ExitFormDate endDate={watchedDate} />
        )}

        <TextArea
          {...register('notes')}
          className="height"
          label={tCommon('note')}
          maxLength={500}
          maxCount={500}
        />
        <div className="display--flex gap--1 justify-content--flex-start margin-top--6">
          <Button type="submit">{tSiva('company.details.contract.endContract')}</Button>
          <Button theme={Theme.Neutral} variant={ButtonVariant.Outlined} type="button" onClick={onCancel}>
            {tCommon('cancel')}
          </Button>
        </div>
      </form>
      <DialogContainer
        show={isActive}
        title={updateError ? tSiva('company.details.contract.endContract.error') : tCommon('confirmation')}
        onClose={updateError ? deactivate : onCancel}
      >
        {updateError ? (
          <>
            <p>{tSiva('changePhase.error.text')}.</p>
            <Button onClick={deactivate}>{tCommon('close')}</Button>
          </>
        ) : (
          <>
            <p>{tSiva('company.details.contract.endContract.success')}</p>
            <Button onClick={onCancel}>{tCommon('close')}</Button>
          </>
        )}
      </DialogContainer>
    </>
  );
};

function ExitFormDate({ endDate }: { endDate: Date | undefined }) {
  const { t: tSiva } = useTranslation('siva');
  const [calculatedExitFormDate] = useCalculateSivaKTUDates(endDate);

  const exitFormDateString: ReactNode = useMemo(() => {
    if (!endDate) return 'Utgangsdato er ikke gitt';
    if (!calculatedExitFormDate) return 'Henter dato...';
    const exit = new Date(calculatedExitFormDate);

    // To avoid TimeZone trouble between portal frontend and SL that I do not want to deal with right now
    exit?.setHours(6);

    return capitalizeFirstLetter(formatDateToNOString(exit, true, '{month} {year}', true));
  }, [endDate, calculatedExitFormDate]);

  return (
    <div className="margin-bottom--3">
      <p className="font-weight--bold">{tSiva('company.details.contract.overview.dispatchExitForm')}</p>
      <p>{exitFormDateString}</p>
    </div>
  );
}

export default EndContract;
