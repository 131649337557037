import { Button } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './HistoryViewEmailContent.scss';

type Props = {
  from: string;
  to: string;
  date: string;
  content: React.ReactNode;
  onClose?: () => void;
};

const HistoryViewEmailContent: React.FC<Props> = ({ from, to, date, content, onClose }) => {
  const { t: tCommon } = useTranslation();

  return (
    <>
      <div className="display--flex">
        <label className="owi-history-email-viewer__label">
          <strong>{tCommon('from')}</strong>
        </label>
        <div>{from}</div>
      </div>

      <div className="display--flex margin-top--1">
        <label className="owi-history-email-viewer__label">
          <strong>{tCommon('to')}</strong>
        </label>
        <div>{to}</div>
      </div>

      <div className="display--flex margin-top--1">
        <label className="owi-history-email-viewer__label">
          <strong>{tCommon('date')}</strong>
        </label>
        <div>{date}</div>
      </div>

      <div className="margin-top--3 owi-history-email-viewer__content">{content}</div>

      {onClose && (
        <Button className="margin-top--6" onClick={onClose}>
          {tCommon('close')}
        </Button>
      )}
    </>
  );
};

export default HistoryViewEmailContent;
