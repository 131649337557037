import { Button, ButtonVariant, Theme } from '@in/component-library';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HttpStatusCode, TimeEntryDto, TimeEntryStatus, TimeEntryUpsertDto } from 'src/api/v2';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import Input from 'src/components/Form/Input';
import { Select } from 'src/components/Form/Select';
import OperationResultList, {
  OperationResultListData,
} from 'src/components/OperationResultList/OperationResultList';
import { useContacts } from 'src/features/contacts';
import useTimeEntries from 'src/hooks/use-time-entries';
import useTimeRates from 'src/hooks/use-time-rates';
import { formatTimeEntryStatus } from 'src/utils/format';
import { convertHoursAndMinutesToHours } from 'src/utils/time';
import { toastPromise } from 'src/utils/toast';
import { SYSTEM_DEFAULT_HOUR_RATE } from '../constants';

type Props = {
  show: boolean;
  selectedTimeEntries: TimeEntryDto[];
  onClose: () => void;
};

export interface MassEditTimeEntryValues {
  hours: number | undefined;
  hourRate: number | undefined;
}

const EditInkindModal: React.FC<Props> = ({ show, onClose, selectedTimeEntries }) => {
  const { t: tCommon } = useTranslation();
  const { t: tSelfEffort } = useTranslation('selfEffort');

  const { contacts } = useContacts();

  const [operationResults, setOperationResults] = useState<OperationResultListData[]>([]);

  const form = useForm<
    Omit<TimeEntryUpsertDto, 'status'> & {
      timeHours: number;
      timeMinutes: number;
      status: TimeEntryStatus | '';
    }
  >({
    defaultValues: {
      timeHours: 0,
      timeMinutes: 0,
    },
  });

  const { createTimeEntryListMutation } = useTimeEntries();
  const { defaultTimeRate, timeRates } = useTimeRates();

  const timeRateOptions = [
    {
      value: '',
      text: tSelfEffort('keepContactHourRate'),
    },
    {
      value: defaultTimeRate?.hourRate || 0,
      text: `${defaultTimeRate?.name || ''} (${(
        defaultTimeRate?.hourRate || SYSTEM_DEFAULT_HOUR_RATE
      ).toLocaleString()} kr)`,
    },
    ...timeRates.map((timeRate) => ({
      value: timeRate.hourRate,
      text: `${timeRate.name} (${timeRate.hourRate.toLocaleString()} kr)`,
    })),
  ];

  const timeEntryStatusOptions = [
    { text: tSelfEffort('keepOriginalStatus'), value: '' },
    { text: tCommon('approved'), value: TimeEntryStatus.Counting },
    { text: tCommon('pendingApproval'), value: TimeEntryStatus.PendingApproval },
    { text: tCommon('nonCounting'), value: TimeEntryStatus.NonCounting },
  ];

  const hourWatch = form.watch('timeHours');
  const minuteWatch = form.watch('timeMinutes');
  const hourRateWatch = form.watch('hourRate');
  const statusWatch = form.watch('status');

  const newTime = useMemo(() => {
    if (hourWatch || minuteWatch) {
      return { hours: hourWatch || 0, minutes: minuteWatch || 0 };
    }

    return undefined;
  }, [hourWatch, minuteWatch]);

  const handleOnClose = () => {
    form.reset();
    setOperationResults([]);
    onClose();
  };

  const handleOnSubmit = form.handleSubmit((formData) => {
    const { timeHours, timeMinutes, ...rest } = formData;
    // kopierer verdien i nytt object
    const data = { ...rest };

    const hours = convertHoursAndMinutesToHours(timeHours, timeMinutes);
    if (isNaN(hours)) {
      form.setError('timeHours', {
        type: 'custom',
        message: tSelfEffort('hoursIsNaN'),
      });
      return;
    }

    // rund av til 2 desimaler
    data.hours = Math.round(hours * 100) / 100;
    // gjør om til tall
    data.hourRate = +data.hourRate;

    const records = selectedTimeEntries.map<TimeEntryUpsertDto>((item) => {
      const hourRate = data.hourRate === 0 ? item.hourRate : data.hourRate;
      const status = data.status === '' ? item.status : data.status;

      const itemHours = hours === 0 ? item.hours : hours;

      return {
        ...item,

        hours: itemHours,
        hourRate,
        status,
      };
    });

    const promise = createTimeEntryListMutation.mutateAsync(records);

    toastPromise(promise, {
      error: tSelfEffort('couldNotChangeInkind'),
    }).then((result) => {
      setOperationResults(
        result.map((item) => {
          const contact = item.data
            ? contacts.find((c) => c.id === item.data?.clusterContactId)
            : contacts.find((c) => c.id === item.request?.clusterContactId);
          let name = '';
          if (contact) {
            name = `${contact.firstName || ''} ${contact.lastName || ''}`;
          }

          return { message: item.message, name, statusCode: item.status };
        }),
      );

      const anyErrors = result.some(
        (item) => item.status !== HttpStatusCode.OK && item.status !== HttpStatusCode.Created,
      );

      if (!anyErrors) {
        setOperationResults([]);
        handleOnClose();
      }
    });
  });

  return (
    <DialogContainer title={tSelfEffort('editSelfEffort')} show={show} onClose={handleOnClose}>
      <form onSubmit={handleOnSubmit}>
        <p>{tSelfEffort('changeMultipleRows')}</p>

        <div className="display--flex gap--1">
          <Input
            type="number"
            {...form.register('timeHours', {
              validate: {
                subZero: (value) => {
                  if (value < 0) {
                    return tSelfEffort('hoursNotLessThanZero');
                  }

                  return true;
                },
                sumSubZero: () => {
                  const h = +form.getValues('timeHours');
                  const m = +form.getValues('timeMinutes');

                  if (h + m === 0) {
                    return true;
                  }

                  if (h + m / 60 < 0) {
                    return tSelfEffort('totalTimeLessThanZeroHours');
                  }

                  return true;
                },
              },
            })}
            label={tSelfEffort('numberOfHours')}
            className="flex--1"
            error={form.formState.errors.timeHours?.message}
          />

          <Input
            type="number"
            {...form.register('timeMinutes', {
              validate: {
                subZero: (value) => {
                  if (value < 0) {
                    return tSelfEffort('minutesCannoteBeLessThanZero');
                  }

                  return true;
                },
                sumSubZero: () => {
                  const h = +form.getValues('timeHours');
                  const m = +form.getValues('timeMinutes');

                  if (h + m / 60 < 0) {
                    return tSelfEffort('totalTimeLessThanZeroHours');
                  }

                  return true;
                },
              },
            })}
            label={tSelfEffort('numberOfMinutes')}
            className="flex--1"
            error={form.formState.errors.timeMinutes?.message}
          />
        </div>

        <div className="margin-bottom--3">
          {newTime
            ? tSelfEffort('newTime', {
                hours: newTime.hours,
                minutes: newTime.minutes,
              })
            : tSelfEffort('noTimechange')}
        </div>

        <Select {...form.register('hourRate')} label={tCommon('timeRate')} options={timeRateOptions} />

        <div className="margin-bottom--3">
          {hourRateWatch
            ? tSelfEffort('newHourlyRate', { hourlyRate: hourRateWatch })
            : tSelfEffort('hourlyRateKept')}
        </div>

        <Select {...form.register('status')} label="Status" options={timeEntryStatusOptions} />

        <div className="margin-bottom--3">
          {statusWatch
            ? tSelfEffort('newStatus', { status: formatTimeEntryStatus(statusWatch) })
            : tSelfEffort('statusKept')}
        </div>

        <OperationResultList data={operationResults} className="margin-bottom--2" />

        <div className="display--flex gap--1 justify-content--flex-start padding-top--3">
          <Button theme={Theme.Neutral} type="submit" isLoading={createTimeEntryListMutation.isLoading}>
            {tCommon('save')}
          </Button>

          <Button
            theme={Theme.Neutral}
            variant={ButtonVariant.Outlined}
            type="button"
            onClick={handleOnClose}
            disabled={createTimeEntryListMutation.isLoading}
          >
            {tCommon('close')}
          </Button>
        </div>
      </form>
    </DialogContainer>
  );
};

export default EditInkindModal;
