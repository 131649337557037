import React from 'react';
import { HelpAndSupportCommonQuestionsSelection } from '../../constants/HelpAndSupportMenu';
import { Button, ListLink } from '@in/component-library';
import { useTranslation } from 'react-i18next';
import CommunicationBetweenActors from './CommunicationBetweenActors';
import Referralprocess from './ReferralProcess';

const CommonQuestions: React.FC = () => {
  const { t: tCommon } = useTranslation();
  const { t: tHelpAndSupport } = useTranslation('onboarding');
  const [subpage, setSubpage] = React.useState<HelpAndSupportCommonQuestionsSelection>('none');

  if (subpage !== 'none') {
    return (
      <div className="help-and-support-common-questions__subpage">
        <h2>{tHelpAndSupport('helpAndSupport.tab.CommonQuestions')}</h2>

        {subpage === 'actorCommunication' && <CommunicationBetweenActors />}
        {subpage === 'referralprocess' && <Referralprocess />}

        <Button
          iconName="fa-light fa-arrow-left"
          iconPosition="left"
          size="medium"
          theme="neutral"
          variant="link"
          className="margin-top--3"
          onClick={() => setSubpage('none')}
        >
          {tCommon('previous')}
        </Button>
      </div>
    );
  }

  return (
    <div>
      <h2>{tHelpAndSupport('helpAndSupport.tab.CommonQuestions')}</h2>
      <div>
        <ListLink
          className="help-and-support-sl-outline-fixer help-and-support-sl__fontweight--400 cursor--pointer"
          header={tHelpAndSupport('commonQuestions.Interplay.CommunicationBetweenActors')}
          onClick={() => setSubpage('actorCommunication')}
          onKeyDown={(e) => {
            if (e.code === 'Enter') setSubpage('actorCommunication');
          }}
          tabIndex={0}
        />
        <ListLink
          className="help-and-support-sl-outline-fixer help-and-support-sl__fontweight--400 cursor--pointer"
          header={tHelpAndSupport('commonQuestions.Interplay.Referralprocess')}
          onClick={() => setSubpage('referralprocess')}
          onKeyDown={(e) => {
            if (e.code === 'Enter') setSubpage('referralprocess');
          }}
          tabIndex={0}
        />
      </div>
    </div>
  );
};

export default CommonQuestions;
