import { createColumnHelper } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ClusterContactDto, ClusterMemberDto, TimeEntryDto } from 'src/api/v2';
import { CONTACT_ROUTES, MEMBER_ROUTES } from 'src/navigation';

import { formatCurrencySimple, formatDateToNOString } from 'src/utils/FormatValue';
import { formatHours, formatTimeEntrySource, formatTimeEntryStatus } from 'src/utils/format';

type InkindColumn = {
  contacts: ClusterContactDto[];
  members: ClusterMemberDto[];
};

export const useInkindColumns = ({ contacts, members }: InkindColumn) => {
  const { t: tCommon } = useTranslation('common');

  return useMemo(() => {
    const columnHelper = createColumnHelper<TimeEntryDto>();

    return [
      columnHelper.accessor((row) => row.createdOn, {
        id: 'added',
        cell: (info) => formatDateToNOString(info.getValue()),
        meta: {
          hide: true,
        },
        minSize: 150,
      }),
      columnHelper.accessor((row) => row.clusterContactName, {
        id: 'resourceBaseContact',
        cell: (info) => {
          return contacts.some((contact) => contact.id === info.row.original.clusterContactId) ? (
            <Link to={CONTACT_ROUTES(info.row.original.clusterContactId!).INDEX}>{info.getValue()}</Link>
          ) : (
            info.getValue()
          );
        },
        minSize: 300,
      }),
      columnHelper.accessor((row) => row.clusterContactEmailAddress, {
        id: 'email',
        cell: (info) => info.getValue(),
        meta: {
          hide: true,
        },
        minSize: 350,
      }),
      columnHelper.accessor((row) => row.clusterMemberName, {
        id: 'memberCompany',
        cell: (info) => {
          return members.some((member) => member.id === info.row.original.clusterMemberId) ? (
            <Link to={MEMBER_ROUTES(info.row.original.clusterMemberId!).INDEX}>{info.getValue()}</Link>
          ) : (
            info.getValue()
          );
        },
        meta: {
          hide: true,
        },
        minSize: 300,
      }),
      columnHelper.accessor((row) => row.clusterContactStatus, {
        id: 'contactStatus',
        cell: (info) => info.getValue(),
        meta: {
          hide: true,
        },
        minSize: 150,
      }),
      columnHelper.accessor((row) => row.reportedDate, {
        id: 'eventDate',
        cell: (info) => formatDateToNOString(info.getValue()),
        meta: {
          hide: true,
        },
        minSize: 175,
      }),
      columnHelper.accessor((row) => row.projectName, {
        id: 'projectName',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => row.eventName, {
        id: 'eventName',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => row.hours, {
        id: 'hours',
        cell: (info) => formatHours(info.getValue()),
        meta: {
          hide: true,
        },
        minSize: 150,
      }),
      columnHelper.accessor((row) => row.hourRate, {
        id: 'hourRate',
        cell: (info) => formatCurrencySimple(info.getValue()),
        meta: {
          hide: true,
        },
        minSize: 150,
      }),
      columnHelper.accessor(
        (row) => {
          if (row) {
            return +(row.hours * row.hourRate).toFixed(0);
          }

          return null;
        },
        {
          id: 'selfEffort',
          cell: (info) => (info.getValue() ? formatCurrencySimple(info.getValue()!) : null),
          minSize: 150,
        },
      ),
      columnHelper.accessor((row) => row.source, {
        id: 'source',
        cell: (info) => tCommon(formatTimeEntrySource(info.getValue())),
        meta: {
          hide: true,
        },
        minSize: 175,
      }),
      columnHelper.accessor((row) => row.status, {
        id: 'selfEffortStatus',
        cell: (info) => formatTimeEntryStatus(info.getValue()),
        minSize: 175,
      }),
      columnHelper.accessor((row) => row.description, {
        id: 'description',
        cell: (info) => info.getValue(),
        minSize: 300,
        meta: {
          hide: true,
        },
      }),
      columnHelper.accessor((row) => row.workGroupName, {
        id: 'workGroup',
        cell: (info) => info.getValue(),
      }),
    ];
  }, [contacts, members, tCommon]);
};
