import React, { useMemo } from 'react';
import { OneWayInOpportunityStatusDto, OneWayInStatus } from 'src/api/v2';
import Table from 'src/components/Table';
import HistoryTableSection, { HistoryTableSectionProps } from './HistoryTableSection';
import { PageLoader } from '@in/component-library';
import { useTranslation } from 'react-i18next';
import useOneWayIn from '../../../hooks/use-one-way-in';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import useToggle from 'src/hooks/use-toggle';
import HistoryViewEmailContent from './HistoryViewEmailContent';
import { formatDateToNOString } from 'src/utils/FormatValue';
import RecommendServiceMailCustomer from '../../../components/recommend-service/RecommendServiceMailCustomer';
import { CustomerMailRecommendedServices } from '../../../models/OneWayInModels';
import HistoryTableINSection from './HistoryTableINSection';
import { sanitizeMinSideEmail } from '../../../utils/htmlTextConverter';

import './OneWayInHistory.scss';

type Props = {
  data: OneWayInOpportunityStatusDto[];
  isLoading?: boolean;
  formId: string;
};

interface EmailViewContent {
  title: string;
  from: string;
  to: string;
  date: string;
  text: string;
  recommendedMail: boolean;
  userName?: string;
  actorName?: string;
  recommendedServices?: CustomerMailRecommendedServices[];
}

const OneWayInHistoryV2: React.FC<Props> = ({ data, isLoading, formId }) => {
  const { t: tCommon } = useTranslation();
  const { t: tOneWayIn } = useTranslation('oneWayIn');

  const { oneWayInOpportunityFormQuery, onewWayInOpportunityFormINResponseMailQuery } = useOneWayIn(formId);

  const {
    isActive: isEmailViewActive,
    activate: activateEmailView,
    deactivate: deactivateEmailView,
  } = useToggle(false);

  const [emailContent, setEmailContent] = React.useState<EmailViewContent>({
    title: '',
    from: '',
    to: '',
    date: '',
    text: '',
    recommendedMail: false,
  });

  const openEmailView = (status: OneWayInOpportunityStatusDto) => {
    if (!status) return;

    setEmailContent({
      title: tOneWayIn('services.recommend.mailpage.customer.title'),
      from: 'noreply@flerbedriftsportal.no',
      to: status.recommendationEmail?.recipientName || '',
      date: formatDateToNOString(status.createdOn, false, '{date}.{month}.{year}', true),
      text: status.recommendationEmail?.emailContent || '',
      recommendedMail: true,
      userName: status.recommendationEmail?.senderName,
      actorName: status.recommendationEmail?.senderOrganisation,
      recommendedServices: status.recommendedServices.map((service) => ({
        id: service.businessId || '',
        name: service.name,
        owner: service.businessName,
        links: service.externalLinks,
      })),
    });

    activateEmailView();
  };

  const openINEmailView = () => {
    if (!onewWayInOpportunityFormINResponseMailQuery.data) return;

    setEmailContent({
      title: onewWayInOpportunityFormINResponseMailQuery.data.tittel,
      from: onewWayInOpportunityFormINResponseMailQuery.data.avsender.epostAdresse,
      to: onewWayInOpportunityFormINResponseMailQuery.data.mottaker.navn,
      date: formatDateToNOString(
        onewWayInOpportunityFormINResponseMailQuery.data.svarDato,
        false,
        '{date}.{month}.{year}',
        true,
      ),
      text: onewWayInOpportunityFormINResponseMailQuery.data.tekst,
      recommendedMail: false,
    });

    activateEmailView();
  };

  const history = useMemo(() => {
    const record: Record<string, OneWayInOpportunityStatusDto[]> = {};

    data.forEach((item) => {
      // Ignore the received status.
      if (item.status === OneWayInStatus.Received) return;

      const _date = new Date(item.createdOn.toString());
      const date = _date.getDate();
      const month = _date.getMonth();
      const year = _date.getFullYear();

      const key = new Date(year, month, date).toISOString();

      if (!record[key]) {
        record[key] = [];
      }

      record[key].push(item);
    });

    const keys = Object.keys(record)
      // dette sorterer fra eldst til nyest
      .sort()
      // så vi reverserer for å få nyeste først
      .reverse();

    const result = keys.map((key) => {
      return {
        date: new Date(key),
        data: [...record[key]].sort((a, b) => {
          const A = new Date(a.createdOn.toString());
          const B = new Date(b.createdOn.toString());

          // A - B = eldst først
          // så vi reverserer for å få nyeste først
          return B.getTime() - A.getTime();
        }),
      } as HistoryTableSectionProps;
    });

    return result;
  }, [data]);

  if (isLoading) {
    return <PageLoader aria-label={tCommon('loadingData')} />;
  }

  return (
    <div className="one-way-in-history__page-div">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header style={{ width: '56px' }}></Table.Header>
            <Table.Header style={{ width: '300px' }}>{tOneWayIn('log.table.column.activity')}</Table.Header>
            <Table.Header style={{ width: '300px' }}>{tOneWayIn('log.table.column.actor')}</Table.Header>
            <Table.Header>{tOneWayIn('log.table.column.behandler')}</Table.Header>
            <Table.Header>{tOneWayIn('log.table.column.description')}</Table.Header>
          </Table.Row>
        </Table.Head>

        <Table.Body>
          {history.map(({ data, date }) => (
            <HistoryTableSection
              key={date.toISOString()}
              data={data}
              date={date}
              openEmailView={openEmailView}
            />
          ))}
          <HistoryTableINSection
            deliverDate={
              onewWayInOpportunityFormINResponseMailQuery.data?.svarDato
                ? formatDateToNOString(onewWayInOpportunityFormINResponseMailQuery.data?.svarDato)
                : ''
            }
            receivedDate={oneWayInOpportunityFormQuery.data?.form.innsendtDato || ''}
            openEmailView={openINEmailView}
            hasEmail={!!onewWayInOpportunityFormINResponseMailQuery.data}
          />
        </Table.Body>
      </Table>
      <DialogContainer title={emailContent.title} show={isEmailViewActive} onClose={deactivateEmailView}>
        <HistoryViewEmailContent
          from={emailContent.from}
          to={emailContent.to}
          date={emailContent.date}
          content={
            emailContent.recommendedMail ? (
              <RecommendServiceMailCustomer
                customerName={emailContent.to}
                note={emailContent.text}
                userName={emailContent.userName || ''}
                actorName={emailContent.actorName || ''}
                recommendedServices={emailContent.recommendedServices || []}
                textView
              />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: sanitizeMinSideEmail(emailContent.text) }} />
            )
          }
          onClose={deactivateEmailView}
        />
      </DialogContainer>
    </div>
  );
};

export default OneWayInHistoryV2;
