import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SivaContractCreateDto, SivaPhase } from 'src/api/v2';
import Page from 'src/components/Page/Page';
import { ROUTES } from 'src/navigation';
import { useBoolean } from 'usehooks-ts';
import NewCompanyBrregSearch from '../../components/company/NewCompanyBrregSearch';
import NewCompanyContract from '../../components/company/NewCompanyContract';
import NewCompanyDialog from '../../components/company/NewCompanyDialog';
import NewCompanyInfo from '../../components/company/NewCompanyInfo';
import { useSivaContracts } from '../../hooks/use-siva-contracts';
import { NewCompanySteps } from '../../types';
import SelectPhase from '../../components/company/SelectPhase';
import { useAuth } from 'src/features/authorization';
import { useAvailablePhases } from '../../hooks/use-available-phases';
import { useFormSteps } from '../../hooks/use-form-steps';

const NewCompanyPage: React.FC = () => {
  const { t: tSiva } = useTranslation('siva');

  const navigate = useNavigate();

  const [companyName, setCompanyName] = useState<string>('');
  const [error, setError] = useState<string | undefined>();

  const confirmationDialog = useBoolean();

  const { mutations } = useSivaContracts();

  const { checkAccess } = useAuth();

  const isBusinessGarden = checkAccess('UI_SivaBusinessGarden').read;
  const { setStep, step, resetStep, stepForward, stepBackward, stepProgressBar } =
    useCreateSivaContractSteps(isBusinessGarden);

  const form = useForm<SivaContractCreateDto>({
    defaultValues: {
      sivaPhase: isBusinessGarden ? SivaPhase.Targeted : undefined,
      hasFemaleFounderChairMemberOrCeo: false,
      name: '',
      ideaSource: '',
      organizationNumber: '',
    },
  });

  const organizationNumber = form.watch('organizationNumber') ?? '';
  const availablePhases = useAvailablePhases(organizationNumber);

  useEffect(() => {
    // safeguards to prevent bad state if users enters a step directly
    // (for example by refreshing the page or using a direct link)
    // necessary due to step state being controlled by url search params instead of react state

    // Check that company is selected on company info page
    // and send back to brreg page if not
    if (step === 'info' && !organizationNumber) {
      setStep('brreg', { replace: true });
    }
    // Check that company is selected when business garden
    // and send back to brreg page if not
    if (step === 'contract' && !organizationNumber && isBusinessGarden) {
      setStep('brreg', { replace: true });
    }
    // If the user ends up on the contract phase without having selected phase
    // (for example if they refresh the browser while on contract phase)
    if (step === 'contract' && !form.getValues('sivaPhase')) {
      // If only one phase available (for example business garden) we can just select that phase
      // Otherwise we have to send them back to select a phase themselves
      if (availablePhases.length === 1) {
        form.setValue('sivaPhase', availablePhases[0]);
      } else {
        setStep('phase', { replace: true });
      }
    }
  }, [organizationNumber, setStep, step, availablePhases, form]);

  const handleOnSubmit = form.handleSubmit(async (data) => {
    // Do not attempt to submit if for example the user presses enter in brreg search
    if (step !== 'contract') return;

    try {
      if (!data.name) {
        data.name = '';
      }

      if (!data.notes) {
        data.notes = '';
      }

      await mutations.create.mutateAsync(data);
    } catch (error) {
      setError(tSiva('add.company.error'));
    } finally {
      confirmationDialog.setTrue();
      window.scrollTo(0, 0);
    }
  });

  return (
    <Page>
      <form onSubmit={handleOnSubmit}>
        <h1>{tSiva('add.company')}</h1>

        {stepProgressBar}

        {step === 'brreg' && (
          <NewCompanyBrregSearch
            initialOrganizationNumber={organizationNumber}
            onClick={(organizationNumber, name) => {
              form.setValue('organizationNumber', organizationNumber);
              setCompanyName(name);
              stepForward?.();
            }}
            onSkip={isBusinessGarden ? undefined : () => setStep('contract')}
          />
        )}

        {step === 'info' && (
          <NewCompanyInfo
            organizationNumber={organizationNumber}
            availablePhases={availablePhases}
            isBusinessGarden={isBusinessGarden}
            stepBackward={stepBackward}
            stepForward={stepForward}
            skipPhaseStep={() => {
              form.setValue('sivaPhase', availablePhases[0]);
              setStep('contract');
            }}
          />
        )}

        {step == 'phase' && (
          <SelectPhase
            form={form}
            availablePhases={availablePhases}
            stepBackward={stepBackward}
            stepForward={stepForward}
          />
        )}

        {step === 'contract' && (
          <NewCompanyContract
            key={organizationNumber}
            organizationNumber={organizationNumber}
            form={form}
            isSaving={mutations.create.isLoading}
            stepBackward={stepBackward}
            stepForward={stepForward}
          />
        )}

        <NewCompanyDialog
          open={confirmationDialog.value}
          onClose={confirmationDialog.setFalse}
          onGoBack={() => {
            confirmationDialog.setFalse();
            navigate(ROUTES.SIVA.IDEAS_AND_COMPANIES.BASE);
          }}
          onReset={() => {
            confirmationDialog.setFalse();
            setError(undefined);
            form.reset();
            resetStep();
          }}
          error={error}
          phase={form.getValues('sivaPhase')}
          name={companyName}
        />
      </form>
    </Page>
  );
};

function useCreateSivaContractSteps(isBusinessGarden: boolean) {
  const steps = useMemo<ReadonlyArray<NewCompanySteps>>(
    () => (isBusinessGarden ? ['brreg', 'info', 'contract'] : ['brreg', 'info', 'phase', 'contract']),
    [isBusinessGarden],
  );
  return useFormSteps(steps);
}

export default NewCompanyPage;
