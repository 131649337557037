import React from 'react';
import { useParams } from 'react-router-dom';
import useOneWayIn from '../../hooks/use-one-way-in';
import { RaskAvklaringViser } from '@in/kunde-raskavklaring-visning';
import { OneWayInContact } from 'src/api/v2';
import { useTranslation } from 'react-i18next';
import { Notification } from '@in/component-library';
import { PageParams } from '../types/PageParams';

const Opportunity: React.FC = () => {
  const { formId } = useParams<PageParams>();
  const { t: tOneWayIn } = useTranslation('oneWayIn');

  const { oneWayInOpportunityFormQuery } = useOneWayIn(formId);

  const form = oneWayInOpportunityFormQuery.data?.form;

  const formContact = React.useMemo(() => {
    if (!form?.kontakt) {
      return {
        id: '',
        navn: '',
        fornavn: '',
        etternavn: '',
        epost: '',
        telefon: '',
      } as OneWayInContact;
    }

    return form?.kontakt;
  }, [form]);

  if (oneWayInOpportunityFormQuery.isError) {
    return (
      <Notification type="error" wrapperRole="alert">
        {tOneWayIn('errorCouldNotGetForm')}
      </Notification>
    );
  }

  return (
    <RaskAvklaringViser
      id={form!.id}
      tittel={''}
      grupper={form!.grupper}
      organisasjon={form!.organisasjon}
      kontakt={formContact}
      innsendtDato={''}
    />
  );
};

export default Opportunity;
