/**
 * Unique id's used for DataTableV3's local storage keys
 * These keys should not be used more then one place.
 *
 * If you need to "reuse" it, you should make a component that uses the same column-settings for the table,
 * but if the data is different, you should use a different id. As it may confuse the user if the column-settings
 * are the "same" for different tables in the application.
 *
 * Structure: DataTableV3Ids.[Feature].[Context]: '[Feature].[Context]'
 * If you need more structure, you can add more levels, but try to keep it as simple as possible.
 */
export const DataTableV3Ids = {
  ResourceBase: {
    Members: {
      Base: 'ResourceBase.Members',
      Grants: 'ResourceBase.Members.Grants',
      Contacts: 'ResourceBase.Members.Contacts',
      FeesAndEffort: 'ResourceBase.Members.FeesAndEffort',
    },
    Contacts: 'ResourceBase.Contacts',
    ContactSelfEffort: 'contact-details-inkind',
    Associates: 'ResourceBase.Associates',
    Leads: 'ResourceBase.Leads',
    WorkGroups: 'ResourceBase.WorkGroups',
    WorgGroupDetailParticipants: 'WorkGroupDetail_Participants',
    Events: {
      Participants: 'ResourceBase.Events_Participants',
    },
    MainGoal: 'ResourceBase.MainGoalDetail',
    FocusAreaDetails: 'Resourcebase.FocusAreaDetails',
    CustomProjectCategory: 'ResourceBase.CustomProjectCategoryDetail',
    Inkind: 'ResourceBase.Inkind',
  } as const,

  OneWayIn: {
    Contacts: 'OneWayIn.Contacts',
    Opportunities: 'OneWayIn.Opportunities',
    OpportunityFeedbackOtherActorsList: 'OneWayIn.OpportunityFeedbackOtherActorsList',
    OpportunityDetailsReferring: 'OneWayIn.Details.Referring',
  } as const,

  AccountSettings: {
    AccessControl: 'AccountSettings.AccessControl',
  } as const,

  Siva: {
    Budget: 'Siva.Budget',
    IdeasAndCompanies: {
      ContractsOverview: 'Siva.IdeasAndCompanies.ContractsOverview',
      ESA: 'Siva.IdeasAndCompanies.ESA',
      CompanyDetails: {
        Contract: 'Siva.IdeasAndCompanies.CompanyDetails.Contract',
        ESA: 'Siva.IdeasAndCompanies.CompanyDetails.ESA',
      },
    },
  } as const,

  SivaAdmin: {
    BudgetPerYear: 'SivaAdmin.BudgetPerYear',
    GoalsPerYear: 'SivaAdmin.GoalsPerYear',
    ExtractGoals: 'SivaAdmin.ExtractGoals',
    FundsOverview: 'SivaAdmin.FundsOverview',
  } as const,
} as const;
