import { useTranslation } from 'react-i18next';
import TertiaryButton from '../TertiaryButton/TertiaryButton';
import { FaIcons } from '@in/component-library';
import React from 'react';

function ResetFilterButton({ onClick, className }: { onClick: () => void; className?: string }) {
  const { t } = useTranslation('buttons');
  return (
    <TertiaryButton
      icon={FaIcons.Redo}
      className={className}
      standardizedSize={'x-large'}
      minimalPadding={true}
      onClick={onClick}
    >
      {t('clearFilter')}
    </TertiaryButton>
  );
}

export default ResetFilterButton;
