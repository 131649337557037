import React from 'react';
import { useTranslation } from 'react-i18next';
import DocumentTitle from 'src/components/DocumentTitle/DocumentTitle';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import { useAuth } from 'src/features/authorization';
import useTimeEntries from 'src/hooks/use-time-entries';
import Page from 'src/components/Page/Page';
import InkindTable from '../components/InkindTable';
import NoAccess from 'src/components/NoAccess/NoAccess';
import useDocumentTitle from 'src/hooks/use-document-title';

const InkindPage = () => {
  const { t: tPageTitles } = useTranslation('pageTitles');
  const { t: tProjects } = useTranslation('projects');

  const { checkAccess, isLoading: isLoadingAuth } = useAuth();
  const { read, write } = checkAccess('Inkind');

  const { isLoading: isLoadingTimeEntries } = useTimeEntries();
  const { documentTitle } = useDocumentTitle(tPageTitles('selfEffort'), tProjects('projects'));

  if (isLoadingAuth || isLoadingTimeEntries) {
    return (
      <DocumentTitle title={documentTitle}>
        <LoadingPage />
      </DocumentTitle>
    );
  }

  if (!read) {
    return <NoAccess />;
  }

  return (
    <DocumentTitle title={documentTitle}>
      <Page>
        <InkindTable writeAccess={write} />
      </Page>
    </DocumentTitle>
  );
};

export default InkindPage;
